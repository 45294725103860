import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const TotalCalls = ({ fetchData1 }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (fetchData1 && fetchData1.length > 0) {
        // Initialize an array for each month
      const monthCounts = Array(12).fill(0); 

      fetchData1.forEach(call => {
        // Get month index (0-11)
        const month = new Date(call.createdAt).getMonth(); 
        // Increment the count for the corresponding month
        monthCounts[month] += 1; 
      });

      console.log("Month Counts:", monthCounts); 
      setChartData(monthCounts);
    }
  }, [fetchData1]);

  const options = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Calls Received per Month'
    },
    xAxis: {
      categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      title: {
        text: 'Month'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Calls'
      }
    },
    series: [{
      name: 'Total Received Calls',
      data: chartData
    }]
  };

  return (
    <div>
      {chartData.length > 0 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      ) : (
        <div>No data available for the chart.</div>
      )}
    </div>
  );
};

export default TotalCalls;


// import React, { useState } from 'react';
// import { AgCharts } from 'ag-charts-react';

// const TotalCalls = ({fetchData}) => {
//     console.log(fetchData)
//     const [chartOptions, setChartOptions] = useState({
//         data: [
//             { month: 'Jan', totalReceivedCalls: 5 },
//             { month: 'Feb', totalReceivedCalls: 3.9 },
//             { month: 'Mar', totalReceivedCalls: 3.0 },
//             { month: 'Apr', totalReceivedCalls: 7 },
//             { month: 'May', totalReceivedCalls: 5 },
//             { month: 'Jun', totalReceivedCalls: 10 },
//         ],
//         series: [{ type: 'bar', xKey: 'month', yKey: 'totalReceivedCalls' }],
//         axes: [
//             {
//                 position: "bottom",
//                 title: "Total Received Calls", // Title for the X-axis
//             },
//             {
//                 position: "left",
//                 min: 0,
//                 max: 10,
//                 ticks: [0, 1.25, 2.5, 3.75, 5, 6.25, 7.5, 8.75, 10],
//                 label: {
//                     formatter: (value) => value.toFixed(2),
//                 },
//             }
//         ],
//     });

//     return (
//         <AgCharts options={chartOptions} />
//     );
// }

// export default TotalCalls;


// //   import React from 'react'
// //   import { Chart as ChartJS, defaults } from "chart.js/auto";
// //   import { Bar, Doughnut, Line } from "react-chartjs-2";
// //   import calldata from "./calldata.json"

// //   const CallTypeChart = () => {
// //     return (
// //       <div className='dataCard cutsomerCard'>
// //         <Bar
// //         data={{
// //             labels:calldata.map((data)=>data.label),
// //             datasets:[
// //                 {
// //                     data:calldata.map((data)=>data.label)
// //                 },
// //             ],
// //         }}/>
// //       </div>
// //     )
// //   }
  
// //   export default CallTypeChart



// import React from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// const TotalCalls = () => {
//   const options = {
//     chart: {
//       type: 'column' // Use 'column' for histogram-like effect
//     },
//     title: {
//       text: 'Calls Received per Month'
//     },
//     xAxis: {
//       categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
//       title: {
//         text: 'Month'
//       }
//     },
//     yAxis: {
//       min: 0,
//       title: {
//         text: 'Number of Calls'
//       }
//     },
//     series: [{
//       name: 'Calls Received',
//       data: [120, 150, 170, 200, 180, 220, 300, 250, 190, 210, 230, 260] // Example data
//     }]
//   };

//   return (
//     <div>
//       <HighchartsReact
//         highcharts={Highcharts}
//         options={options}
//       />
//     </div>
//   );
// };

// export default TotalCalls;


// import React, { useEffect, useState } from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// const TotalCalls = ({ fetchData }) => {
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     const getData = async () => {
//       const response = await fetchData(); // Fetch data from your API
//       const callsData = response.data;

//       // Create an object to hold the count of calls per month
//       const callsPerMonth = {};

//       // Process the data to count calls per month
//       callsData.forEach(call => {
//         const month = new Date(call.createdAt).toLocaleString('default', { month: 'long' }); // Get month name
//         callsPerMonth[month] = (callsPerMonth[month] || 0) + 1; // Increment the count for the corresponding month
//       });

//       // Convert the object to an array for the chart
//       const months = [
//         'January', 'February', 'March', 'April', 'May', 'June', 
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ];
//       const data = months.map(month => callsPerMonth[month] || 0); // Fill in 0 for months with no calls

//       setChartData(data);
//     };

//     getData();
//   }, [fetchData]);

//   const options = {
//     chart: {
//       type: 'column' // Use 'column' for histogram-like effect
//     },
//     title: {
//       text: 'Calls Received per Month'
//     },
//     xAxis: {
//       categories: [
//         'January', 'February', 'March', 'April', 'May', 'June', 
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ],
//       title: {
//         text: 'Month'
//       }
//     },
//     yAxis: {
//       min: 0,
//       title: {
//         text: 'Number of Calls'
//       }
//     },
//     series: [{
//       name: 'Calls Received',
//       data: chartData // Use the processed data
//     }]
//   };

//   return (
//     <div>
//       <HighchartsReact
//         highcharts={Highcharts}
//         options={options}
//       />
//     </div>
//   );
// };

// export default TotalCalls;


// import React, { useEffect, useState } from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// const TotalCalls = ({ fetchData }) => {
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     if (fetchData && fetchData.data) {
//       const monthCounts = Array(12).fill(0); // Initialize an array for each month

//       fetchData.data.forEach(call => {
//         const month = new Date(call.createdAt).getMonth(); // Get month index (0-11)
//         monthCounts[month] += 1; // Increment the count for the corresponding month
//       });

//       setChartData(monthCounts);
//     }
//   }, [fetchData]);

//   const options = {
//     chart: {
//       type: 'column'
//     },
//     title: {
//       text: 'Calls Received per Month'
//     },
//     xAxis: {
//       categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
//       title: {
//         text: 'Month'
//       }
//     },
//     yAxis: {
//       min: 0,
//       title: {
//         text: 'Number of Calls'
//       }
//     },
//     series: [{
//       name: 'Calls Received',
//       data: chartData // Use the aggregated data
//     }]
//   };

//   return (
//     <div>
//       <HighchartsReact
//         highcharts={Highcharts}
//         options={options}
//       />
//     </div>
//   );
// };

// export default TotalCalls;
// State Management: We use useState to manage the chart data.
// Effect Hook: The useEffect hook processes the fetchData prop whenever it changes.
// Month Extraction: For each call in fetchData.data, we extract the month using new Date(call.createdAt).getMonth(), which returns a zero-based index (0 for January, 1 for February, etc.).
// Data Aggregation: We maintain an array monthCounts to count the number of calls for each month.
// Chart Configuration: Finally, the aggregated data is passed to the chart configuration.