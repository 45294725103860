import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import "./personal.css";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  validateName,
  validatePhoneNumberWithCountryCode,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validateSocialSecurity,
  validateNumber,
  validateMedicareNumber,
  validatePercentOwn,
  validateURL,
} from "../../validation/Validation";

const UserProfile = () => {
  const { jsonApi } = useApis();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [dob, setDob] = useState(null);
  const [anniversary, setAnniversary] = useState(null);
  const [partA, setPartA] = useState(null);
  const [partB, setPartB] = useState(null);
  const [partC, setPartC] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const init = async () => {
    const res = await jsonApi(
      appConstant.getSingleUserDetail,
      { id: searchParams.get("id") },
      {},
      false
    );
    if (!res?.error) {
      setSearchParams({
        id: res.data.data._id,
      });
      setData(res.data.data);
      populateForm(res.data.data);
    }
  };
  useEffect(() => {
    init();
  }, [searchParams.get("id")]);

  const onSubmit = async (data) => {
    const dataWithUserId = {
      type: "user",
      ...data,
      userId: searchParams.get("id"),
    };
    console.log(data);

    console.log("Data with userId:", dataWithUserId);

    // Make API call to update user data
    const response = await jsonApi(
      appConstant.updateUserSpouse,
      null,
      dataWithUserId,
      null
    );
    console.log(data);

    console.log("Response:", response);

    if (response.status === "success") {
      console.log(data);
      console.log("Data submitted successfully");
      reset();
      console.log("addadd", reset());
    } else {
      console.error("Unexpected response:", response);
    }
  };

  const populateForm = (data) => {
    if (data?.contactDetail) {
      Object.keys(data.contactDetail).forEach((key) => {
        setValue(`contactDetail.${key}`, data.contactDetail[key]);
      });
    }
    if (data?.primaryResidence) {
      Object.keys(data?.primaryResidence).forEach((key) => {
        setValue(`primaryResidence.${key}`, data.primaryResidence[key]);
      });
    }
    if (data?.workInfo) {
      Object.keys(data?.workInfo).forEach((key) => {
        setValue(`workInfo.${key}`, data.workInfo[key]);
      });
    }
    if (data?.otherInfo) {
      Object.keys(data?.otherInfo).forEach((key) => {
        setValue(`otherInfo.${key}`, data.otherInfo[key]);
      });
    }
  };

  useEffect(() => {
    if (dob) {
      const calculateAge = (dateOfBirth) => {
        var today = new Date();
        var birthDate = new Date(dateOfBirth); // create a date object directly from `dob1` argument
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        console.log(age);
        return age;
      };
      const age = calculateAge(dob);
      setError("");
      setValue("contactDetail.age", age);
    }
  }, [dob, setValue]);

  const today = new Date(); // Current date
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const handleDateChange = (date, type) => {
    if (date instanceof Date && !isNaN(date)) {
      if (type === "dob") {
        setDob(date);
        setValue("contactDetail.dob", date, { shouldValidate: true });
      } else if (type === "anniversary") {
        setAnniversary(date);
        setValue("contactDetail.weddingAnniv", date, { shouldValidate: true });
      } else if (type === "partA") {
        setPartA(date);
        setValue("otherInfo.partAEnrollmentDate", date, {
          shouldValidate: true,
        });
      } else if (type === "partB") {
        setPartB(date);
        setValue("otherInfo.partBEnrollmentDate", date, {
          shouldValidate: true,
        });
      } else if (type === "partC") {
        setPartC(date);
        setValue("otherInfo.partCEnrollmentDate", date, {
          shouldValidate: true,
        });
      }

      setError("");
    } else {
      setError("Invalid date format. Please select a valid date.");
    }
  };

  const hasError = Object.keys(errors).length > 0;
  const getGeneralErrorMessage = () => {
    if (hasError) {
      return "Enter fill the necessary details!";
    }
    return "";
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col">
              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      {/* Start Page Content */}
                      {hasError && (
                        <div className="alert alert-danger text-center">
                          {/* {getGeneralErrorMessage()} */}
                          {getGeneralErrorMessage()}
                        </div>
                      )}
                      <div className="row mt-4">
                        <div className="col-lg-8">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Contact Details
                          </h4>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group row">
                                <label
                                  htmlFor="name"
                                  className="col-sm-5 col-form-label"
                                >
                                  Name
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    id="name"
                                    type="text"
                                    maxLength={256}
                                    placeholder="John Luther"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.name
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register("contactDetail.name", {
                                      required: true,
                                      validate: validateName,
                                    })}
                                  />

                                  {errors.contactDetail?.name && (
                                    <p className="text-danger">
                                      {errors.contactDetail?.name.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="cellNo"
                                  className="col-sm-5 col-form-label"
                                >
                                  Cell No
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    placeholder="+198787878765"
                                    maxLength={15}
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.cellNo
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register("contactDetail.cellNo", {
                                      required: true,
                                      validate:
                                        validatePhoneNumberWithCountryCode,
                                    })}
                                  />

                                  {errors.contactDetail?.cellNo && (
                                    <p className="text-danger">
                                      {errors.contactDetail?.cellNo.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="personalEmail"
                                  className="col-sm-5 col-form-label"
                                >
                                  Email
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="personalEmail"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.personalEmail
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="email"
                                    maxLength={256}
                                    placeholder="abc@gmail.com"
                                    {...register(
                                      "contactDetail.personalEmail",
                                      {
                                        required: true,
                                        validate: validateEmail,
                                      }
                                    )}
                                  />
                                  {errors.contactDetail?.personalEmail && (
                                    <p className="text-danger">
                                      {
                                        errors.contactDetail?.personalEmail
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="socialSecurity"
                                  className="col-sm-5 col-form-label"
                                >
                                  Social Security #
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    id="socialSecurity"
                                    type="text"
                                    maxLength={256}
                                    placeholder="123-45-6789"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.socialSecurity
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register(
                                      "contactDetail.socialSecurity",
                                      {
                                        required: true,
                                        validate: validateSocialSecurity,
                                      }
                                    )}
                                  />
                                  {errors.contactDetail?.socialSecurity && (
                                    <p className="text-danger">
                                      {
                                        errors.contactDetail?.socialSecurity
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="citizenshipStatus"
                                  className="col-sm-5 col-form-label"
                                >
                                  Citizenship Status
                                </label>
                                <div className="col-sm-7">
                                  <select
                                    id="citizenshipStatus"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.citizenshipStatus
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register(
                                      "contactDetail.citizenshipStatus",
                                      {
                                        required: true,
                                      }
                                    )}
                                  >
                                    <option value="">Select</option>
                                    <option value="US Citizen">
                                      US Citizen
                                    </option>
                                    <option value="LPR/Green Card (Legal Permanent Resident)">
                                      LPR/Green Card (Legal Permanent Resident)
                                    </option>
                                    <option value="F-1 Visa (Student or Work Visa)">
                                      F-1 Visa (Student or Work Visa)
                                    </option>
                                    <option value="B-1 or B-2 Visa">
                                      B-1 or B-2 Visa
                                    </option>
                                    <option value="Undocumented Immigrant">
                                      Undocumented Immigrant
                                    </option>
                                  </select>
                                  {errors.contactDetail?.citizenshipStatus && (
                                    <div className="text-danger">
                                      {
                                        errors.contactDetail.citizenshipStatus
                                          .message
                                      }
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="gender"
                                  className="col-sm-5 col-form-label"
                                >
                                  Gender
                                </label>
                                <div className="col-sm-7">
                                  <select
                                    id="gender"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.gender
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register("contactDetail.gender", {
                                      required: true,
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Prefer not to respond">
                                      Prefer not to respond
                                    </option>
                                  </select>
                                  {errors.contactDetail?.gender && (
                                    <div className="text-danger">
                                      {errors.contactDetail.gender.message}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="dob"
                                  className="col-sm-5 col-form-label"
                                >
                                  DOB
                                </label>
                                <div className="col-sm-7">
                                  <DatePicker
                                    selected={dob}
                                    onChange={(date) =>
                                      handleDateChange(date, "dob")
                                    }
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={maxDate}
                                    customInput={
                                      <input
                                        type="text"
                                        maxLength={256}
                                        className={`form-control borderStyle ${
                                          errors.contactDetail?.dob
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="DD/MM/YYYY"
                                        {...register("contactDetail.dob", {
                                          required: true,
                                        })}
                                      />
                                    }
                                  />
                                  {errors.contactDetail?.dob && (
                                    <p className="text-danger">
                                      {errors.contactDetail?.dob.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="age"
                                  className="col-sm-5 col-form-label"
                                >
                                  Age
                                </label>
                                <div className="col-sm-7">
                                  <div className="row">
                                    <div className="col-7 input-group">
                                      <input
                                        id="age"
                                        type="text"
                                        maxLength={5}
                                        className="form-control border-style"
                                        {...register("contactDetail.age", {
                                          required: true,
                                        })}
                                        readOnly
                                      />
                                    </div>
                                    <div className="input-group-text">yrs.</div>
                                  </div>
                                  {errors.contactDetail?.age && (
                                    <div className="invalid-feedback">
                                      {errors.contactDetail.age.message}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group row">
                                <label
                                  htmlFor="clientRank"
                                  className="col-sm-5 col-form-label"
                                >
                                  Client Rank
                                </label>
                                <div className="col-sm-7">
                                  <select
                                    id="clientRank"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.clientRank
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register("contactDetail.clientRank", {
                                      required: true,
                                    })}
                                  >
                                    <option value="">Select</option>
                                    <option value="Platinum">Platinum</option>
                                    <option value="Gold">Gold</option>
                                    <option value="Special">Special</option>
                                    <option value="Former">Former</option>
                                  </select>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="relationshipType"
                                  className="col-sm-5 col-form-label"
                                >
                                  Relationship Type
                                </label>
                                <div className="col-sm-7">
                                  <select
                                    id="relationshipType"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.relationshipType
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register(
                                      "contactDetail.relationshipType",
                                      { required: true }
                                    )}
                                  >
                                    <option value="">Select</option>
                                    <option value="Single">Single</option>
                                    <option value="Married">Married</option>
                                    <option value="Cohabitation (Living Together)">
                                      Cohabitation (Living Together)
                                    </option>
                                    <option value="Separated">Separated</option>
                                    <option value="Divorced">Divorced</option>
                                  </select>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="weddingAnniv"
                                  className="col-sm-5 col-form-label"
                                >
                                  Wedding Anniversary
                                </label>
                                <div className="col-sm-7">
                                  <DatePicker
                                    selected={anniversary}
                                    onChange={(date) =>
                                      handleDateChange(date, "anniversary")
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={maxDate}
                                    customInput={
                                      <input
                                        type="text"
                                        className={`form-control borderStyle ${
                                          errors.contactDetail?.weddingAnniv
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        placeholder="DD/MM/YYYY"
                                        {...register(
                                          "contactDetail.weddingAnniv",
                                          {}
                                        )}
                                      />
                                    }
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="yearOfMarriage"
                                  className="col-sm-5 col-form-label"
                                >
                                  Year of Marriage
                                </label>
                                <div className="col-sm-7">
                                  <div className="row">
                                    <div className="col-7 input-group">
                                      <input
                                        id="yearOfMarriage"
                                        type="text"
                                        maxLength={256}
                                        className={`form-control borderStyle ${
                                          errors.contactDetail?.yearOfMarriage
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        {...register(
                                          "contactDetail.yearOfMarriage"
                                        )}
                                      />
                                    </div>
                                    <div className="input-group-text">yrs.</div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="driversLicense"
                                  className="col-sm-5 col-form-label"
                                >
                                  Drivers License #
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    id="driversLicense"
                                    type="text"
                                    placeholder="AB1234432C43"
                                    maxLength={128}
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.driversLicense
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register(
                                      "contactDetail.driversLicense",
                                      {
                                        required: true,
                                        validate: validateNumber,
                                      }
                                    )}
                                  />
                                  {errors.contactDetail?.driversLicense && (
                                    <p className="text-danger">
                                      {
                                        errors.contactDetail?.driversLicense
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="driversLicenseState"
                                  className="col-sm-5 col-form-label"
                                >
                                  Drivers License State
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    id="driversLicenseState"
                                    type="text"
                                    maxLength={256}
                                    placeholder="US"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.driversLicenseState
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register(
                                      "contactDetail.driversLicenseState",
                                      { required: true }
                                    )}
                                  />
                                  {errors.contactDetail
                                    ?.driversLicenseState && (
                                    <p className="text-danger">
                                      {
                                        errors.contactDetail
                                          ?.driversLicenseState.message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Primary Residence
                          </h4>

                          <div className="form-group row">
                            <label
                              htmlFor="streetAddress"
                              className="col-sm-5 col-form-label"
                            >
                              Street Add.
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className={`form-control borderStyle ${
                                  errors.contactDetail?.streetAddress
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="streetAddress"
                                maxLength={256}
                                placeholder="1435 Cedarwood Drive, San Mateo, CA, 89521"
                                {...register("contactDetail.streetAddress", {
                                  required: true,
                                })}
                              />

                              {errors.contactDetail?.streetAddress && (
                                <p className="text-danger">
                                  {errors.contactDetail?.streetAddress.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="city"
                              className="col-sm-5 col-form-label"
                            >
                              City
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className={`form-control borderStyle ${
                                  errors.contactDetail?.city ? "is-invalid" : ""
                                }`}
                                placeholder="Los Angeles"
                                id="city"
                                maxLength={256}
                                {...register("contactDetail.city", {
                                  required: true,
                                  validate: (value) =>
                                    validateName(value, "city"),
                                })}
                              />

                              {errors.contactDetail?.city && (
                                <p className="text-danger">
                                  {errors.contactDetail?.city.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="suiteNo"
                              className="col-sm-5 col-form-label"
                            >
                              Suite #
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className={`form-control borderStyle ${
                                  errors.contactDetail?.suiteNo
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="suiteNumber"
                                maxLength={256}
                                placeholder="9859"
                                {...register("contactDetail.suiteNo", {
                                  required: true,
                                  validate: validateSuiteNumber,
                                })}
                              />

                              {errors.contactDetail?.suiteNo && (
                                <p className="text-danger">
                                  {errors.contactDetail?.suiteNo.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="state"
                              className="col-sm-5 col-form-label"
                            >
                              State
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className={`form-control borderStyle ${
                                  errors.contactDetail?.state
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="California"
                                maxLength={256}
                                id="state"
                                {...register("contactDetail.state", {
                                  required: true,
                                  validate: (value) =>
                                    validateName(value, "state"),
                                })}
                              />
                              {errors.contactDetail?.state && (
                                <p className="text-danger">
                                  {errors.contactDetail?.state.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="zip"
                              className="col-sm-5 col-form-label"
                            >
                              Zip
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className={`form-control borderStyle ${
                                  errors.contactDetail?.zip ? "is-invalid" : ""
                                }`}
                                id="zip"
                                maxLength={5}
                                placeholder="78458"
                                {...register("contactDetail.zip", {
                                  required: true,
                                  validate: validateZipCode,
                                })}
                              />
                              {errors.contactDetail?.zip && (
                                <p className="text-danger">
                                  {errors.contactDetail?.zip.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="yearsThere"
                              className="col-sm-5 col-form-label"
                            >
                              Years There
                            </label>
                            <div className="col-sm-7">
                              <div className="row">
                                <div className="col-7 input-group">
                                  <input
                                    id="yearsThere"
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.contactDetail?.yearsThere
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    maxLength={2}
                                    {...register("contactDetail.yearsThere", {
                                      required: true,
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Years There must be a number",
                                      },
                                    })}
                                  />
                                  {errors.contactDetail?.yearsThere && (
                                    <p className="text-danger">
                                      {
                                        errors.contactDetail?.yearsThere
                                          ?.message
                                      }
                                    </p>
                                  )}
                                </div>
                                <div className="input-group-text">yrs.</div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group row business-search">
                            <label
                              htmlFor="linkedPersonalContact"
                              className="col-sm-5 col-form-label"
                            >
                              Linked Personal Contact
                            </label>
                            <div className="col-sm-7">
                              <input
                                id="linkedPersonalContact"
                                type="text"
                                className="typeaheadcomp form-control text-truncate"
                                placeholder="Search for business contact"
                                maxLength={256}
                                {...register(
                                  "primaryResidence.linkedPersonalContact"
                                )}
                              />
                              <i
                                className="form-control-icon"
                                data-feather="search"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end row*/}
                    </div>
                  </div>
                  {/* multi-column ordering */}
                </div>
              </div>
              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-auto">
                              <h4 className="page-title text-truncate text-dark font-weight-medium">
                                Work Info
                              </h4>
                            </div>
                            <div className="col"></div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="companyName"
                                  className="col-sm-5 col-form-label"
                                >
                                  Company Name
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.companyName
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="companyName"
                                    maxLength={256}
                                    placeholder="Enter company name"
                                    {...register("workInfo.companyName", {
                                      required: true,
                                      validate: (value) =>
                                        validateName(value, "name"),
                                    })}
                                  />
                                  {errors.workInfo?.companyName && (
                                    <p className="text-danger">
                                      {errors.workInfo?.companyName?.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="position"
                                  className="col-sm-5 col-form-label"
                                >
                                  Position
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.position
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="position"
                                    maxLength={256}
                                    placeholder="Manager"
                                    {...register("workInfo.position", {
                                      required: true,
                                    })}
                                  />
                                  {errors.workInfo?.position && (
                                    <p className="text-danger">
                                      {errors.workInfo.position.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="directNumber"
                                  className="col-sm-5 col-form-label"
                                >
                                  Direct #
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    placeholder="+198787873637"
                                    maxLength={15}
                                    id="directNumber"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.directNumber
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register("workInfo.directNumber", {
                                      required: true,
                                      validate:
                                        validatePhoneNumberWithCountryCode,
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="corporateNumber"
                                  className="col-sm-5 col-form-label"
                                >
                                  Corporate #
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    placeholder="+198787873637"
                                    maxLength={15}
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.corporateNumber
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register("workInfo.corporateNumber", {
                                      required: true,
                                      validate:
                                        validatePhoneNumberWithCountryCode,
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="extensionNumber"
                                  className="col-sm-5 col-form-label"
                                >
                                  Extension #
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    placeholder="+198787873637"
                                    maxLength={15}
                                    id="extensionNumber"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.extensionNumber
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    {...register("workInfo.extensionNumber", {
                                      required: true,
                                      validate:
                                        validatePhoneNumberWithCountryCode,
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="workEmail"
                                  className="col-sm-5 col-form-label"
                                >
                                  Email
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="email"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.workEmail
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="workEmail"
                                    maxLength={256}
                                    placeholder="abc@gmail.com"
                                    {...register("workInfo.workEmail", {
                                      required: true,
                                      validate: validateEmail,
                                    })}
                                  />
                                  {errors.workInfo?.workEmail && (
                                    <p className="text-danger">
                                      {errors.workInfo?.workEmail?.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="url"
                                  className="col-sm-5 col-form-label"
                                >
                                  URL
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.url ? "is-invalid" : ""
                                    }`}
                                    id="url"
                                    maxLength={256}
                                    placeholder="www.abccompany.com"
                                    {...register("workInfo.url", {
                                      required: true,
                                      validate: validateURL,
                                    })}
                                  />
                                  {errors.workInfo?.url && (
                                    <p className="text-danger">
                                      {errors.workInfo?.url?.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="streetAddress"
                                  className="col-sm-5 col-form-label"
                                >
                                  Street Address
                                </label>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.streetAddress
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="streetAddress"
                                    maxLength={256}
                                    placeholder="Enter street address"
                                    {...register("workInfo.streetAddress", {
                                      required: true,
                                    })}
                                  />
                                  {errors.workInfo?.streetAddress && (
                                    <p className="text-danger">
                                      {errors.workInfo.streetAddress.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="suiteNo"
                                  className="col-sm-5 col-form-label"
                                >
                                  Suite #
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.suiteNo
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="suiteNumber"
                                    maxLength={128}
                                    placeholder="Suite Number"
                                    {...register("workInfo.suiteNo", {
                                      required: true,
                                      validate: validateSuiteNumber,
                                    })}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="dept"
                                  className="col-sm-5 col-form-label"
                                >
                                  Dept
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.dept ? "is-invalid" : ""
                                    }`}
                                    id="dept"
                                    maxLength={128}
                                    placeholder="IT Deptartment"
                                    {...register("workInfo.dept", {
                                      required: true,
                                    })}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="mailStop"
                                  className="col-sm-5 col-form-label"
                                >
                                  Mail Stop
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.mailStop
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="mailStop"
                                    maxLength={256}
                                    placeholder="BLDG-45-ROOM-101"
                                    {...register("workInfo.mailStop", {
                                      required: true,
                                      pattern: {
                                        value: /^[a-zA-Z0-9\- ]{1,20}$/,
                                        message:
                                          "Mailstop must be between 1 and 20 characters and can include letters, numbers, hyphens, and spaces.",
                                      },
                                    })}
                                  />
                                  {errors.workInfo?.mailStop && (
                                    <p className="text-danger">
                                      {errors.workInfo?.mailStop?.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="city"
                                  className="col-sm-5 col-form-label"
                                >
                                  City
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.city ? "is-invalid" : ""
                                    }`}
                                    id="city"
                                    maxLength={128}
                                    placeholder="Amsterdam"
                                    {...register("workInfo.city", {
                                      required: true,
                                      validate: (value) =>
                                        validateName(value, "city"),
                                    })}
                                  />
                                  {errors.workInfo?.city && (
                                    <p className="text-danger">
                                      {errors.workInfo.city.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="state"
                                  className="col-sm-5 col-form-label"
                                >
                                  State
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.state ? "is-invalid" : ""
                                    }`}
                                    placeholder="Enter State"
                                    id="state"
                                    maxLength={256}
                                    {...register("workInfo.state", {
                                      required: true,
                                      validate: (value) =>
                                        validateName(value, "state"),
                                    })}
                                  />
                                  {errors.workInfo?.state && (
                                    <p className="text-danger">
                                      {errors.workInfo.state.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="zip"
                                  className="col-sm-5 col-form-label"
                                >
                                  Zip
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.zip ? "is-invalid" : ""
                                    }`}
                                    id="zip"
                                    maxLength={5}
                                    placeholder="Enter zip code"
                                    {...register("workInfo.zip", {
                                      required: true,
                                      validate: validateZipCode,
                                    })}
                                  />
                                  {errors.workInfo?.zip && (
                                    <p className="text-danger">
                                      {errors.workInfo.zip.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="canCallAtWork"
                                  className="col-sm-5 col-form-label"
                                >
                                  Can Call At Work
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    className={`form-control borderStyle ${
                                      errors.workInfo?.canCallAtWork
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="canCallAtWork"
                                    maxLength={256}
                                    placeholder="Yes/No"
                                    {...register("workInfo.canCallAtWork", {
                                      required: true,
                                    })}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="owner"
                                  className="col-sm-5 col-form-label"
                                >
                                  Owner
                                </label>
                                <div
                                  className={`col-sm-7 pt-2 form-control  ${
                                    errors.workInfo?.owner ? "is-invalid" : ""
                                  }`}
                                >
                                  <div className="form-check form-check-inline ">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="ownerYes"
                                      value="Yes"
                                      {...register("workInfo.owner", {
                                        required: true,
                                      })}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor="ownerYes"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="ownerNo"
                                      value="No"
                                      {...register("workInfo.owner")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ownerNo"
                                    >
                                      No
                                    </label>
                                  </div>
                                  {errors.workInfo?.owner && (
                                    <p className="text-danger">
                                      {errors.workInfo?.owner.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="percentageOwn"
                                  className="col-sm-5 col-form-label"
                                >
                                  Percentage Own
                                </label>
                                <div className="col-sm-7">
                                  <div className="row">
                                    <div className="col-8 input-group">
                                      <input
                                        type="text"
                                        className={`form-control ${
                                          errors.workInfo?.percentOwn
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        maxLength={4}
                                        id="percentageOwn"
                                        {...register("percentOwn", {
                                          required:
                                            "Percentage Own is required",
                                          validate: validatePercentOwn,
                                          valueAsNumber: true,
                                        })}
                                        max="100"
                                        min="1"
                                        step="1" // Ensures only whole numbers are accepted
                                        onInput={(e) => {
                                          let value = e.target.value.replace(
                                            /[^0-9]/g,
                                            ""
                                          );
                                          if (value.length > 3) {
                                            value = value.slice(0, 3);
                                          }
                                          if (Number(value) > 100) {
                                            value = "100";
                                          }
                                          e.target.value = value;
                                        }}
                                      />
                                    </div>
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">%</div>
                                    </div>
                                  </div>
                                  {errors.workInfo?.percentageOwn && (
                                    <p className="text-danger">
                                      {errors.workInfo.percentageOwn.message}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="linkedBusinessContact"
                                  className="col-sm-5 col-form-label"
                                >
                                  Linked Business Contact
                                </label>
                                <div className="col-sm-7 pt-2">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="linkedBusinessContactYes"
                                      value="Yes"
                                      {...register(
                                        "workInfo.linkedBusinessContact",
                                        { required: true }
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="linkedBusinessContactYes"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="linkedBusinessContactNo"
                                      value="No"
                                      {...register(
                                        "workInfo.linkedBusinessContact"
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="linkedBusinessContactNo"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group row business-search">
                                <label
                                  htmlFor="search"
                                  className="col-sm-5 col-form-label"
                                >
                                  Search
                                </label>
                                <div className="col-sm-7 d-flex align-items-center">
                                  <input
                                    type="text"
                                    className="typeaheadcomp form-control text-truncate borderStyle"
                                    id="search"
                                    maxLength={256}
                                    placeholder="Search for business contact"
                                    {...register("workInfo.search")}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body custom-field-button">
                  <div className="row">
                    <div className="col-12 align-self-center">
                      <div className="row">
                        <div className="col-lg-12">
                          <h4 className="page-title text-truncate text-dark font-weight-medium mb-4">
                            Other Info
                          </h4>

                          <div className="row">
                            {/* First Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="petsName"
                                  className="col-sm-4 col-form-label"
                                >
                                  Pets Name
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    maxLength={256}
                                    type="text"
                                    className="form-control borderStyle"
                                    id="petsName"
                                    {...register("otherInfopetsName")}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="typeOfPets"
                                  className="col-sm-4 col-form-label"
                                >
                                  Type Of Pets
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.typeOfPets
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="typeOfPets"
                                    {...register("otherInfo.typeOfPets")}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="hobbies"
                                  className="col-sm-4 col-form-label"
                                >
                                  Hobbies
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.hobbies
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="hobbies"
                                    {...register("otherInfo.hobbies")}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Second Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <label
                                  htmlFor="medicarePlanType"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare Plan Type
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.medicarePlanType
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="medicarePlanType"
                                    placeholder="Gold"
                                    {...register("otherInfo.medicarePlanType", {
                                      required: true,
                                      validate: (value) =>
                                        validateName(value, "Plan Type"),
                                    })}
                                  />
                                  {errors.otherInfo?.medicarePlanType && (
                                    <p className="text-danger">
                                      {
                                        errors.otherInfo?.medicarePlanType
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicareCompany"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare Company
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.medicareCompany
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="medicareCompany"
                                    placeholder="LIC"
                                    {...register("otherInfo.medicareCompany", {
                                      required: true,
                                      validate: (value) =>
                                        validateName(value, "Company Name"),
                                    })}
                                  />
                                  {errors.otherInfo?.medicareCompany && (
                                    <p className="text-danger">
                                      {
                                        errors.otherInfo?.medicareCompany
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label
                                  htmlFor="medicare"
                                  className="col-sm-4 col-form-label"
                                >
                                  Medicare
                                </label>
                                <div className="col-sm-7">
                                  <input
                                    type="text"
                                    maxLength={256}
                                    className={`form-control borderStyle ${
                                      errors.otherInfo?.medicare
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="medicare"
                                    placeholder="123-45-6789"
                                    {...register("otherInfo.medicare", {
                                      required: true,
                                      validate: validateMedicareNumber,
                                    })}
                                  />
                                  {errors.otherInfo?.medicare && (
                                    <p className="text-danger">
                                      {errors.otherInfo?.medicare.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Third Column */}
                            <div className="col-sm-4">
                              <div className="form-group row">
                                <div className="col">
                                  <label
                                    htmlFor="medicareEnrollmentDates"
                                    className="col form-label mt-n2"
                                  >
                                    Medicare Enrollment Dates
                                  </label>
                                  <div className="form-row col-sm-10">
                                    <div className="col d-flex align-items-center">
                                      <label
                                        htmlFor="partAEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part A
                                      </label>

                                      <DatePicker
                                        selected={partA}
                                        onChange={(date) =>
                                          handleDateChange(date, "partA")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partAEnrollmentDate"
                                            {...register(
                                              "otherInfo.partAEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partAEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partAEnrollmentDate.message
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="col d-flex align-items-center">
                                      <label
                                        htmlFor="partBEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part B
                                      </label>

                                      <DatePicker
                                        selected={partB}
                                        onChange={(date) =>
                                          handleDateChange(date, "partB")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partBEnrollmentDate"
                                            {...register(
                                              "otherInfo.partBEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partBEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partBEnrollmentDate.message
                                          }
                                        </p>
                                      )}
                                    </div>
                                    <div className="col d-flex align-items-center">
                                      <label
                                        htmlFor="partCEnrollmentDate"
                                        className="mb-0 mr-4"
                                      >
                                        Part C
                                      </label>

                                      <DatePicker
                                        selected={partC}
                                        onChange={(date) =>
                                          handleDateChange(date, "partC")
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        maxDate={maxDate}
                                        showIcon
                                        customInput={
                                          <input
                                            type="text"
                                            className="form-control form-control-sm borderStyle"
                                            placeholder="DD/MM/YYYY"
                                            id="partCEnrollmentDate"
                                            {...register(
                                              "otherInfo.partCEnrollmentDate"
                                            )}
                                          />
                                        }
                                      />
                                      {errors.otherInfo
                                        ?.partCEnrollmentDate && (
                                        <p className="text-danger">
                                          {
                                            errors.otherInfo
                                              ?.partCEnrollmentDate.message
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  {/* Start Page Content */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        {/* Button Container */}
                        <div className="col-12 d-flex justify-content-center">
                          <div className="form-group row">
                            <button
                              type="submit"
                              className="next-step btn_green mx-2"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end row*/}
                  </div>
                </div>
                {/* multi-column ordering */}
              </div>

              {/* multi-column ordering */}
            </div>
          </form>
        </div>
      </div>

      {/* End Container fluid  */}
    </>
  );
};

export default UserProfile;
