import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "../../json/appConstant.json";
import { Context } from "../../context/Context";
import useApis from "../../apis/useApis";
import DragsAndDrop from "../../image/DragsAndDrop";

const UpdateCompanyProfile = () => {
  const { userData, setUserData } = useContext(Context);
  const { jsonApi ,mediaApi} = useApis();
  console.log(userData);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [error, setError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  useEffect(() => {
    if (userData?.name) {
      setValue("name", userData?.name || "");
      setValue("userName", userData?.userName || "");
      setValue("email", userData?.email || "");
    }
  }, [userData, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data.logo) {
      formData.append("logo", data.logo); // data.logo is a FileList, get the first file
    }
    try {
      const response = await jsonApi(
        appConstant.updateCompanyProfile,
        null,
        data,
        null
      );

      console.log(response);
      if (!response.error) {
        setSuccessMessage("Profile updated successfully!");
        setError("");
        setUserData({
          ...userData,
          ...data,
        });
      } else {
        setError(response.error.message || "An error occurred");
      }
      if (data.logo) {
        const formData = new FormData();
        formData.append("logo", data.logo); // data.logo is a FileList, get the first file
        const response2 = await mediaApi(
          appConstant.updateProfileImageOfAdmin,
          null,
          formData,
          null
        ); 
        if (!response2.error){
          setSuccessMessage("Profile image updated successfully!");
          setError("");
        }
      }
    } catch (err) {
      setError("An unexpected error occurred");
    }
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  return (
    <div className="page-wrapper">
      <div className="page-breadcrumb">
        <div className="row">
          <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
            Update Profile
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}

                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Name</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={256}
                            placeholder="Name"
                            className={`form-control ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            {...field}
                            required
                          />
                        )}
                      />
                      {errors.name && (
                        <p className="error">{errors.name.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      User Name
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="userName"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            maxLength={256}
                            placeholder="User Name"
                            className={`form-control ${
                              errors.userName ? "is-invalid" : ""
                            }`}
                            {...field}
                            required
                          />
                        )}
                      />
                      {errors.userName && (
                        <p className="error">{errors.userName.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Email</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="email"
                            placeholder="Email"
                            maxLength={256}
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            {...field}
                            disabled
                            required
                          />
                        )}
                      />
                      {errors.email && (
                        <p className="error">{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">Profile</label>
                    <div className="col-lg-10 col-md-9 col-sm-8 " >
                      <div style={{width:"400px"}}>

                    <DragsAndDrop
                    imgKey={userData?.profileImage}
                    className={"admin-profile-round"}
                    heading="Upload Image"
                    inputName="Image"
                    aspect={1 / 1}
                    uploadFile={(x) => {
                      setValue("logo", x);
                    }}
                  />
                      </div>
                      {errors.email && (
                        <p className="error">{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <a href="#">Digital Company</a>.
      </footer>
    </div>
  );
};

export default UpdateCompanyProfile;
