import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";

const HierarchyContractType = forwardRef(({ submitForm, data }, ref) => {
  console.log(data);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      industry: "SGA", // Set default checked value if needed
    },
  });
  useImperativeHandle(ref, () => ({
    handleSubmit: () =>
      handleSubmit((formData) => {
        submitForm(formData);
      })(),
  }));
  useEffect(() => {
    // Determine the default industry based on the number of users
    const userCount = data.users.length;
    let contractType = "SingleAgent"; // Default fallback

    if (userCount >= 301) {
      contractType = "InsuranceCompany";
    } else if (userCount >= 51) {
      contractType = "FMO";
    } else if (userCount >= 11) {
      contractType = "SGA";
    } else if (userCount >= 6) {
      contractType = "MGA";
    } else if (userCount >= 2) {
      contractType = "GeneralAgent";
    } else if (userCount === 1) {
      contractType = "SingleAgent";
    }

    // Set the default value for the 'industry' field
    reset({ contractType: contractType });
  }, [data.users.length]);

  return (
    <div className="my-5 w-100">
      <label className="font-weight-bold h4">Type Of Business</label>
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <ul className="check_list">
            <li>{data?.typeOfIndustry}</li>
          </ul>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table display no-wrap w-100 containt_type_table">
          <thead>
            <tr>
              <th>Control Level</th>
              <th># Users</th>
              <th>Cost Per User</th>
              <th>Min. Monthly Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="SingleAgent"
                    name="contractType"
                    value="SingleAgent"
                    className="custom-control-input"
                    {...register("contractType")}
                  />
                  <label className="custom-control-label" htmlFor="SingleAgent">
                    Single Agent
                  </label>
                </div>
              </td>
              <td>1</td>
              <td>$200</td>
              <td>$200</td>
            </tr>
            <tr>
              <td>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="GeneralAgent"
                    name="contractType"
                    value="GeneralAgent"
                    className="custom-control-input"
                    {...register("contractType")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="GeneralAgent"
                  >
                    General Agent
                  </label>
                </div>
              </td>
              <td>2-5</td>
              <td>$190</td>
              <td>$380</td>
            </tr>
            <tr>
              <td>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="MGA"
                    name="contractType"
                    value="MGA"
                    className="custom-control-input"
                    {...register("contractType")}
                  />
                  <label className="custom-control-label" htmlFor="MGA">
                    MGA
                  </label>
                </div>
              </td>
              <td>6-10</td>
              <td>$180</td>
              <td>$1,080</td>
            </tr>
            <tr>
              <td>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="SGA"
                    name="contractType"
                    value="SGA"
                    className="custom-control-input"
                    {...register("contractType")}
                  />
                  <label className="custom-control-label" htmlFor="SGA">
                    SGA
                  </label>
                </div>
              </td>
              <td>11-50</td>
              <td>$150</td>
              <td>$1,650</td>
            </tr>
            <tr>
              <td>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="FMO"
                    name="contractType"
                    value="FMO"
                    className="custom-control-input"
                    {...register("contractType")}
                  />
                  <label className="custom-control-label" htmlFor="FMO">
                    FMO
                  </label>
                </div>
              </td>
              <td>51-300</td>
              <td>$125</td>
              <td>$6,375</td>
            </tr>
            <tr>
              <td>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="InsuranceCompany"
                    name="contractType"
                    value="InsuranceCompany"
                    className="custom-control-input"
                    {...register("contractType")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="InsuranceCompany"
                  >
                    Insurance Company
                  </label>
                </div>
              </td>
              <td>301+</td>
              <td>$115</td>
              <td>$34,615</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default HierarchyContractType;
