import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { useNavigate } from "react-router-dom";
import useAuthApis from "../../apis/useAuthApis";
import { Context } from "../../context/Context";
import { Eye, EyeOff } from "react-feather";

const ProfilePasswordUpdate = () => {
  const { jsonApi } = useApis();
  const { homeApi } = useAuthApis();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const { userData } = useContext(Context);

  // Separate state for each password field visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  // Regular expression for strong password
  const strongPasswordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const onSubmit = async (data) => {
    try {
      const response = await jsonApi(
        appConstant.updatePassword,
        null,
        data,
        null
      );
      if (!response.error) {
        reset({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setSuccessMessage("Password Updated Successfully");
        setError("");

        await delay(3000);
        if (!userData.defaultPasswordReset) {
          await homeApi();
          navigate("/");
        }
      } else {
        setError(response.message || "Failed to update password.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("An unexpected error occurred.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="page-breadcrumb">
        <div className="row">
          <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
            Update Password
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Old Password
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="oldPassword"
                        control={control}
                        render={({ field }) => (
                          <div className="input-group mb-3">
                            <input
                              type={showOldPassword ? "text" : "password"}
                              placeholder="Old Password"
                              className={`form-control ${errors.oldPassword ? "is-invalid" : ""}`}
                              {...register("oldPassword", {
                                required: "Old Password is required",
                              })}
                            />
                            <span
                              onClick={toggleOldPasswordVisibility}
                              style={{ cursor: "pointer" }}
                              className="input-group-text"
                            >
                              {showOldPassword ? <Eye className="feather-icon" /> : <EyeOff className="feather-icon" />}
                            </span>
                          </div>
                        )}
                      />
                      {errors.oldPassword && (
                        <p className="text-danger">{errors.oldPassword.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      New Password
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="newPassword"
                        control={control}
                        render={({ field }) => (
                          <div className="input-group mb-3">
                            <input
                              type={showNewPassword ? "text" : "password"}
                              placeholder="New Password"
                              className={`form-control ${errors.newPassword ? "is-invalid" : ""}`}
                              {...register("newPassword", {
                                required: "New Password is required",
                                pattern: {
                                  value: strongPasswordRegex,
                                  message: "Password must meet the requirements",
                                },
                              })}
                            />
                            <span
                              onClick={toggleNewPasswordVisibility}
                              style={{ cursor: "pointer" }}
                              className="input-group-text"
                            >
                              {showNewPassword ? <Eye className="feather-icon" /> : <EyeOff className="feather-icon" />}
                            </span>
                          </div>
                        )}
                      />
                      {errors.newPassword && (
                        <p className="text-danger">{errors.newPassword.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Confirm Password
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <div className="input-group mb-3">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm Password"
                              className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                              {...register("confirmPassword", {
                                required: "Confirm Password is required",
                                validate: (value) =>
                                  value === getValues("newPassword") || "Passwords do not match",
                              })}
                            />
                            <span
                              onClick={toggleConfirmPasswordVisibility}
                              style={{ cursor: "pointer" }}
                              className="input-group-text"
                            >
                              {showConfirmPassword ? <Eye className="feather-icon" /> : <EyeOff className="feather-icon" />}
                            </span>
                          </div>
                        )}
                      />
                      {errors.confirmPassword && (
                        <p className="text-danger">{errors.confirmPassword.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Update Password
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
            />
          </div>
        </div>
      </div>
      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <a href="#">Digital Company</a>.
      </footer>
    </div>
  );
};

export default ProfilePasswordUpdate;
