import React, { useEffect, useState } from "react";
import businessData from "../../../../json/businessData.json"
import PaginationComponent from "../../../pagination/PaginationComponent";
import appConstant from "../../../../json/appConstant.json";
import useApis from "../../../../apis/useApis";
import FavButton from "./FavButton";


const ContactHumanData = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const fetchContactHuman = async () => {
    try {
      const contactHuman = await jsonApi(
        appConstant.getContactHuman,
        { page, limit, search: search, type:"Human Resource" },
        null,
        true
      );

      if (!contactHuman.error) {
        setAllContactDetails(contactHuman.data.data || []); // Update state with actual data
        setTotalData(contactHuman.data.total);
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);

  const splitAddressIntoLines = (address, maxLength = 20) => {
    if (!address) return null;

    const lines = [];
    for (let i = 0; i < address.length; i += maxLength) {
      lines.push(address.slice(i, i + maxLength));
    }
    return lines;
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <div className="row ">{/*/Col*/}</div>
              {/*/Row*/}

              {/*/Row*/}
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-12 mb-2">
                            <table
                              id="default_order"
                              className="table table-hover table-striped display no-wrap w-100"
                            >
                              <thead>
                                <tr>
                                  {businessData.headers2
                                    // .filter((header) => header !== "Edit")
                                    // .concat(["Employment Level"])
                                    .map((header, index) => (
                                      <th scope="col" key={index}>
                                        {header}
                                      </th>
                                    ))}
                                </tr>
                              </thead>
                              <tbody>
                                {allContactDetails &&
                                allContactDetails.length > 0 ? (
                                  allContactDetails.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {(page - 1) * limit + index + 1}
                                          <FavButton
                                            item={item}
                                            init={fetchContactHuman}
                                          />
                                        </td>
                                        <td>{item.companyName || "No Data"}</td>
                                        <td>
                                          {item.primaryContactNumber ||
                                            "No Data"}
                                        </td>
                                        <td>{item.email || "No Data"}</td>
                                        <td>
                                          {item.employmentLevel || "No Data"}
                                        </td>
                                        <td>
                                          {splitAddressIntoLines(
                                            item.streetAddress || "No Data"
                                          ).map((line, index) => (
                                            <div key={index}>{line}</div>
                                          ))}
                                        </td>
                                        <td>
                                          {splitAddressIntoLines(
                                            item.primaryResponsibility ||
                                              "No Data"
                                          ).map((line, index) => (
                                            <div key={index}>{line}</div>
                                          ))}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="5">No data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHumanData;
