import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";

const HierarchyCorporatePermissions = forwardRef(
  ({ data, submitForm }, ref) => {
    const { register, handleSubmit, reset } = useForm({
      defaultValues: {
        permissions: {
          ContactCenter: false,
          Barge: false,
          CallMonitoring: false,
          CallRecording: false,
          CallTransfer: false,
          CallWaiting: false,
          CallWhispering: false,
          Conferencing: false,
          Chat: false,
          DoNotDisturb: false,
          Email: false,
          Fax: false,
          FileSharing: false,
          Phone: false,
          SMS: false,
          Text: false,
          VideoConferencing: false,
          Marketing: false,
          DirectMail: false,
          mEmail: false,
          Facebook: false,
          Google: false,
          Instagram: false,
          Linkedin: false,
          SMSText: false,
          Twitter: false,
        },
      },
    });

    useEffect(() => {
      if (data) {
        // const updatedValues = [];
        // for (let index = 0; index < permissions.length; index++) {
        //   const element = permissions[index];
        //   console.log(element[id] = );
        // }

        // reset(updatedValues);

        const updatedValues = permissions.reduce(
          (acc, product) => {
            acc.permissions[product.id] = data.corporatePermissions.some(
              (item) => item === product.id
            );
            return acc;
          },
          { permissions: {} }
        );
        console.log(updatedValues.permissions);
        reset(updatedValues); // Reset the form with new values
      }
    }, [data, reset]);

    // Expose the handleSubmit method to the parent component
    useImperativeHandle(ref, () => ({
      handleSubmit: () =>
        handleSubmit((formData) => {
          const selectedPermissions = Object.keys(formData.permissions)
            .filter((key) => formData.permissions[key])
            .map((key) => key);
          submitForm({ corporatePermissions: selectedPermissions });
        })(),
    }));

    const permissions = [
      { id: "ContactCenter", label: "Contact Center" },
      { id: "Barge", label: "Barge" },
      { id: "CallMonitoring", label: "Call Monitoring" },
      { id: "CallRecording", label: "Call Recording" },
      { id: "CallTransfer", label: "Call Transfer" },
      { id: "CallWaiting", label: "Call Waiting" },
      { id: "CallWhispering", label: "Call Whispering" },
      { id: "Conferencing", label: "Conferencing" },
      { id: "Chat", label: "Chat" },
      { id: "DoNotDisturb", label: "Do Not Disturb" },
      { id: "Email", label: "Email" },
      { id: "Fax", label: "Fax" },
      { id: "FileSharing", label: "File Sharing" },
      { id: "Phone", label: "Phone" },
      { id: "SMS", label: "SMS" },
      { id: "Text", label: "Text" },
      { id: "VideoConferencing", label: "Video Conferencing" },
      { id: "DirectMail", label: "Direct Mail" },
      { id: "mEmail", label: "mEmail" },
      { id: "Facebook", label: "Facebook" },
      { id: "Google", label: "Google" },
      { id: "Instagram", label: "Instagram" },
      { id: "Linkedin", label: "Linkedin" },
      { id: "SMSText", label: "SMS Text" },
      { id: "Twitter", label: "Twitter" },
    ];

    return (
      <div className="my-5 w-100 text-dark">
        <div className="row">
          <div className="col-lg-8 mb-4">
            <div className="row">
              {permissions.slice(0, 8).map((permission) => (
                <div className="col-sm-6" key={permission.id}>
                  <div className="row">
                    <div className="col-9">{permission.label}</div>
                    <div className="col-3">
                      <div className="toggle_small_switch">
                        <label htmlFor={permission.id}>
                          <input
                            type="checkbox"
                            id={permission.id}
                            {...register(`permissions.${permission.id}`)}
                          />
                          <span>
                            <small />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className="card card-block card-stretch card-height">
              <div className="card-header d-flex justify-content-between bg-white">
                <div className="header-title">
                  <h5 className="card-title">Marketing</h5>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  {permissions.slice(8).map((permission) => (
                    <div className="col-4" key={permission.id}>
                      <label>{permission.label}</label>
                      <div className="col-8">
                        <div className="toggle_small_switch">
                          <label htmlFor={permission.id}>
                            <input
                              type="checkbox"
                              id={permission.id}
                              {...register(`permissions.${permission.id}`)}
                            />
                            <span>
                              <small />
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default HierarchyCorporatePermissions;
