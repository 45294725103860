import React, { useState, useEffect } from "react";
import useApiAxios from "./../apis/useAuthApis";
const ImageComponent = ({ src, className }) => {
  const [imageUrl, setImageUrl] = useState("/imgLoader.gif");
  // const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getImageApi } = useApiAxios();

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (!src) return;
      if (src.startsWith("data:im") || src.startsWith("/assets")) {
        setImageUrl(src);
        setLoading(false);
      } else {
        const url = await getImageApi(src);
        console.log(src);
        if (url.error) {
          setError(true);
          setLoading(false);
        } else {
          setError(false);
          setImageUrl(url.data.data.split("?")[0]);
          setLoading(false);
        }
      }
    };

    fetchImageUrl();
  }, [src]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading image: </div>;

  return <img src={imageUrl} className={className} alt="" />;
};

export default ImageComponent;
