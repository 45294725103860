import React, { useEffect, useState } from "react";

const AddStatesModal = ({ currentSelectedState, currentProduct, submit }) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedStates, setSelectedStates] = useState({});

  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const usTerritories = [
    "American Samoa",
    "Puerto Rico",
    "Guam",
    "The U.S. Virgin Islands",
    "The Northern Mariana Islands",
  ];

  const toggleSelectAll = () => {
    const newSelectedStates = {};
    states.forEach((state) => {
      newSelectedStates[state] = !isAllSelected;
    });
    usTerritories.forEach((territory) => {
      newSelectedStates[territory] = !isAllSelected;
    });
    setSelectedStates(newSelectedStates);
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    const newSelectedStates = {
      ...selectedStates,
      [id]: checked,
    };
    setSelectedStates(newSelectedStates);

    // Check if all states and territories are selected
    const allSelected = [...states, ...usTerritories].every(
      (state) => newSelectedStates[state]
    );
    setIsAllSelected(allSelected);
  };

  useEffect(() => {
    if (currentSelectedState) {
      const initialSelectedStates = states
        .concat(usTerritories)
        .reduce((acc, state) => {
          acc[state] = currentSelectedState.includes(state);
          return acc;
        }, {});
      setSelectedStates(initialSelectedStates);
      setIsAllSelected(
        states
          .concat(usTerritories)
          .every((state) => initialSelectedStates[state])
      );
    }
  }, [currentSelectedState]);

  return (
    <div
      className="modal fade"
      id="addStates"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="modal-title text-dark font-weight-medium"
              id="exampleModalLabel"
            >
              Add States
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <label className="font-weight-bold">Product Name</label>
            <p>{currentProduct}</p>
            <label className="font-weight-bold">Select States</label>
            <div>
              <div className="custom-checkbox form-check-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="selectAll"
                  checked={isAllSelected}
                  onChange={toggleSelectAll}
                />
                <label className="custom-control-label" htmlFor="selectAll">
                  Select All
                </label>
              </div>
            </div>
            <ul className="select_state">
              {states.map((state) => (
                <li key={state}>
                  <div className="custom-checkbox form-check-inline">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={state}
                      checked={selectedStates[state] || false}
                      onChange={handleCheckboxChange}
                    />
                    <label className="custom-control-label" htmlFor={state}>
                      {state}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
            <div className="row">
              <div className="col-12 mt-4 mb-3">
                <label className="font-weight-bold">Select US Territory</label>
              </div>
              {usTerritories.map((territory) => (
                <div className="col-sm-6 mb-2" key={territory}>
                  <div className="custom-checkbox form-check-inline">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={territory}
                      checked={selectedStates[territory] || false}
                      onChange={handleCheckboxChange}
                    />
                    <label className="custom-control-label" htmlFor={territory}>
                      {territory}
                    </label>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-12 text-center py-5">
                <button
                  data-dismiss="modal"
                  aria-label="Close"
                  href="#!"
                  className="btn_white py-3 px-5"
                >
                  Cancel
                </button>
                <a
                  href="#!"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => submit(selectedStates)}
                  className="btn_red py-3 px-5"
                >
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStatesModal;
