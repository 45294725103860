import React, { useCallback, useEffect, useState } from "react";
import PaginationComponent from "../pagination/PaginationComponent";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import debounce from 'lodash/debounce';
import "./spineer.css"
const SearchBased = ({ search, setSearch,isSearching }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const { jsonApi } = useApis();
  const [searchBasedDetails, setSearchBasedDetails] = useState([]);
  const [error, setError] = useState("");
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading]=useState(false)
  const [showPagination, setShowPagination] = useState(false); // State to control pagination visibility

  const fetchContactHuman = async () => {
    setLoading(true)
    setShowPagination(false)
    try {
      const contactHuman = await jsonApi(
        appConstant.getSearchContact,
        {
          page,
          limit,
          search,
          type: ["Human Resource", "Business Info"],
        },
        null,
        true
      );
      if (!contactHuman.error) {
        if(contactHuman.data.total===0){
          setNoResults(true);
        }else{
          setNoResults(false)
          setSearchBasedDetails(contactHuman.data.data || []);
        setTotalData(contactHuman.data.total);
        setShowPagination(true); // Show pagination when data is available

        }  
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const debounceFetchContactHuman=useCallback(
    debounce(fetchContactHuman,500),
    [page,limit,search]
  )

  useEffect(() => {
    if (search.length>0) {
      debounceFetchContactHuman()
    } else {
      setSearchBasedDetails([]);
      setTotalData(0);
      setNoResults(true)
      setShowPagination(false); // Show pagination when data is available

    }

    return ()=>{debounceFetchContactHuman.cancel()};

  }, [page, limit, search,debounceFetchContactHuman]);


  const renderData = () => {
    return searchBasedDetails.map((item, index) => (
      <tr key={index}>
        <td>{(page - 1) * limit + index + 1}</td>
        <td>{item.primaryName || "Not Available"}</td>
        <td>{item.title || "Not Available"}</td>
        <td>{item.primaryContactNumber || "Not Available"}</td>
        <td>{item.email || "Not Available"}</td>
        <td>{item.businessName || "Not Available"}</td>
        <td>{item.type || "Not Available"}</td>
      </tr>
    ));
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button"></div>
            {error && <div className="alert alert-danger">{error}</div>}
            {/*/Row*/}
            {loading && (
             <div className="alert alert-info text-center fetching-message">
             <div className="spinner"></div>
             Fetching data...
           </div>
         )}
            {search.length === 0 ? (
              <div className="alert alert-warning text-center">
                Please enter text to view the required data.
              </div>
            ): (noResults  && !loading) ? (<div className="alert alert-info text-center">
              Data not found
            </div>
          ):(
            !loading && showPagination && (
              <div className="row">
                <div className="col-12 mb-5">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <table
                              id="default_order"
                              className="table table-striped table-bordered display no-wrap"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>First Name</th>
                                  <th>Title</th>
                                  <th>Phone Number</th>
                                  <th>Email</th>
                                  <th>Business Name</th>
                                  <th>Business Type</th>
                                </tr>
                              </thead>
                              <tbody>{renderData()}</tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBased;
