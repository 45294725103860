import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const PasswordResetForm = ({ resetPasswordApi, email }) => {
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); 

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      otp: "",
      password: "",
      passwordConfirm: "",
      email: email,
    },
  });

  // Regular expression for strong password
  const strongPasswordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const onSubmit = async (data) => {
    try {
      const response = await resetPasswordApi({
        otp: data.otp,
        password: data.password,
        passwordConfirm: data.passwordConfirm,
        email,
      });

      if (response.error) {
        // Set the error message directly based on the response message
        setErrorMessage(response.message === 'OTP is incorrect' ? "Incorrect OTP" : response.message);
      }
       else {
        reset({
        otp: "",
        password:"",
        passwordConfirm: "",
        });
        setSuccessMessage("Password Updated Successfully");
        setErrorMessage("");
        await delay(3000);
        navigate("/");
      }
    } catch (error) {
      setErrorMessage("Error resetting password");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mt-3">
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <label htmlFor="otp">OTP</label>
          <Controller
            name="otp"
            control={control}
            rules={{ required: "OTP is required" }}
            render={({ field }) => (
              <input
                type="text"
                maxLength={4}
                className="form-control"
                {...field}
                onClick={() => setErrorMessage("")}
                placeholder="Your OTP"
              />
            )}
          />
          {errors.otp && (
            <medium className="text-danger d-block mt-2">
              {errors.otp.message}
            </medium>
          )}
        </div>

        <div className="form-group mt-3">
          <label htmlFor="password">New Password</label>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required",
              pattern: {
                value: strongPasswordRegex,
                message: "Password must meet the requirements",
              },
            }}
            render={({ field }) => (
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  {...field}
                  maxLength={256}
                  onClick={() => setErrorMessage("")}
                  placeholder="Your New Password"
                  id="password"
                  aria-label="Password"
                  aria-describedby="basic-addon2"
                />
              </div>
            )}
          />
          {errors.password && (
            <medium className="text-danger d-block mt-2">
              {errors.password.message}
            </medium>
          )}
        </div>

        <div className="form-group mt-3">
          <label htmlFor="passwordConfirm">Confirm Password</label>
          <Controller
            name="passwordConfirm"
            control={control}
            rules={{
              required: "Password confirmation is required",
              validate: (value) =>
                value === watch("password") || "Passwords do not match",
            }}
            render={({ field }) => (
              <input
                type="password"
                className="form-control"
                maxLength={256}
                {...field}
                onClick={() => setErrorMessage("")}
                placeholder="Confirm Your Password"
              />
            )}
          />
          {errors.passwordConfirm && (
            <medium className="text-danger d-block mt-2">
              {errors.passwordConfirm.message}
            </medium>
          )}
        </div>

        <input
          type="submit"
          defaultValue="Log In"
          className="btn btn-block btn-primary"
        />
      </form>
    </>
  );
};

export default PasswordResetForm;
