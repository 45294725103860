import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PaginationComponent from "../pagination/PaginationComponent";
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import businessData from "../../json/businessData.json"
import ShowNotes from "../notes/ShowNotes";
import useContactHuman from "../../hooks/useContactHuman";

const ContactHistory = () => {
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");
  const { jsonApi } = useApis();
  const [activeTab, setActiveTab] = useState("All"); // Default active tab
  const [error, setError] = useState("");

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setToggle(false);
  };


  const fetchContactHuman = async () => {
    try {
      const contactHuman = await jsonApi(
        appConstant.getContactHuman,
        { page, limit, search: search, type:["Human Resource","Business Info"]},
        null,
        true
      );

      if (!contactHuman.error) {
        setAllContactDetails(contactHuman.data.data || []); // Update state with actual data
        setTotalData(contactHuman.data.total);
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);

  const splitAddressIntoLines = (address, maxLength = 30) => {
    if (!address) return null;

    const lines = [];
    for (let i = 0; i < address.length; i += maxLength) {
      lines.push(address.slice(i, i + maxLength));
    }
    return lines;
  };

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body">
              <div className="row mb-5">
                <div className="col-12 align-self-center">
                  <h4 className="page-title text-truncate text-dark font-weight-medium mb-1">
                    Contact History
                  </h4>
                  {/* Start Page Content */}
                  {/* START TABS DIV */}
                  
                   <div className="dropdown mb-4">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      aria-expanded={toggle}
                      onClick={handleToggle}
                    >
                      {activeTab} <i className={`fa ${toggle ? "fa-caret-up" : "fa-caret-down"}`}></i>
                    </button>
                    <ul
                      className={`dropdown-menu ${toggle ? "show" : ""}`}
                      aria-labelledby="dropdownMenuButton"
                    
                    >

                      {businessData.businessInfoHeader.map((item, index) => (
                        <li key={index}>
                          <Link
                            className={`dropdown-item ${activeTab === item ? "active" : ""}`}
                            to="#"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default anchor behavior
                              handleTabChange(item);
                            }}
                          >
                            {item}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div> 
  
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "all" ? "show active" : ""
                      }`}
                      id="all"
                      role="tabpanel"
                      aria-labelledby="all-tab"
                    >
                      <div className="table-responsive">
                        <div
                          id="default_order_wrapper"
                          className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                        >
                          <PaginationComponent
                            setPage={setPage}
                            totalData={totalData}
                            limit={limit}
                            setLimit={setLimit}
                            page={page}
                            setSearch={setSearch}
                          >
                            <div className="row">
                              <div className="col-sm-12">
                                <table
                                  id="default_order"
                                  className="table table-striped table-bordered display no-wrap"
                                  style={{ width: "100%" }}
                                >
                                  <thead>
                                    <tr>
                                      {businessData.headers2.map((header, index) => (
                                        <th scope="col" key={index}>
                                          {header}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allContactDetails &&
                                    allContactDetails.length > 0 ? (
                                      allContactDetails.map((item, index) => (
                                        <tr key={index}>
                                          <td>{(page - 1) * limit + index + 1}</td>
                                          <td>{item.companyName || "No Data"}</td>
                                          <td>{item.primaryContactNumber || "No Data"}</td>
                                          <td>{item.email || "No Data"}</td>
                                          <td>{item.employmentLevel || "No Data"}</td>
                                          <td>
                                            {splitAddressIntoLines(
                                              item.streetAddress || "No Data"
                                            ).map((line, index) => (
                                              <div key={index}>{line}</div>
                                            ))}
                                          </td>
                                          <td>
                                            {splitAddressIntoLines(
                                              item.primaryResponsibility || "No Data"
                                            ).map((line, index) => (
                                              <div key={index}>{line}</div>
                                            ))}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="7">No data available</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </PaginationComponent>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "notes" ? "show active" : ""
                      }`}
                      id="notes"
                      role="tabpanel"
                      aria-labelledby="notes-tab"
                    >
                      <p>
                        <ShowNotes usePageWrapper={false} />
                      </p>
                    </div>

                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "emails" ? "show active" : ""
                      }`}
                      id="emails"
                      role="tabpanel"
                      aria-labelledby="emails-tab"
                    >
                      <p>
                        Emails
                      </p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "phone calls" ? "show active" : ""
                      }`}
                      id="phone-calls"
                      role="tabpanel"
                      aria-labelledby="phone-calls-tab"
                    >
                      <p>
                        Phone Calls
                      </p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "text messages" ? "show active" : ""
                      }`}
                      id="text-messages"
                      role="tabpanel"
                      aria-labelledby="text-messages-tab"
                    >
                      <p>
                      Text Messages
                      </p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "us postal mail" ? "show active" : ""
                      }`}
                      id="us-postal-mail"
                      role="tabpanel"
                      aria-labelledby="us-postal-mail-tab"
                    >
                      <p>
                        Us Potal Mail
                      </p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "chats" ? "show active" : ""
                      }`}
                      id="chats"
                      role="tabpanel"
                      aria-labelledby="chats-tab"
                    >
                      <p>
                       Chats
                      </p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "fax" ? "show active" : ""
                      }`}
                      id="fax"
                      role="tabpanel"
                      aria-labelledby="fax-tab"
                    >
                      <p>
                       Fax
                      </p>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        activeTab.toLowerCase() === "video conferencing" ? "show active" : ""
                      }`}
                      id="video-conferencing"
                      role="tabpanel"
                      aria-labelledby="video-conferencing-tab"
                    >
                      <p>
                      Video Conferencing
                      </p>
                    </div>
                    
                  </div>
                  {/* end row*/}
                </div>
              </div>
              {/* multi-column ordering */}
            </div>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="Ads"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactHistory;





//<ul
//   className={`nav nav-tabs responsive-tabs ${
//     toggle ? "open" : " "
//   }`}
//   id="myTab"
//   role="tablist"
// >
//   {toggle && (
//     <>
//       {data.businessInfoHeader.map((item, index) => (
//         <li
//           className={`nav-item ${
//             activeTab === item.toLowerCase() ? "active" : ""
//           }`}
//           key={index}
//         >
//           <Link
//             className={`nav-link ${
//               activeTab === item.toLowerCase() ? "active" : ""
//             }`}
//             id=" "
//             onClick={() =>
//               handleTabChange(item.toLowerCase())
//             }
//             role="tab"
//             aria-controls={item.toLowerCase()}
//             aria-selected={
//               activeTab === item.toLowerCase() ? true : false
//             }
//           >
//             {item}
//           </Link>
//         </li>
//       ))}
//     </>
//   )}
//   <div onClick={handleToggle}>
//     <i
//       className={`fa ${
//         toggle ? "fa-caret-up" : "fa-caret-down"
//       }`}
//     ></i>
//   </div>
// </ul>