import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Phone, UserCheck, Award } from "react-feather";
import PaginationComponent from "../pagination/PaginationComponent";
import DatePicker from "react-datepicker";
import { format } from "date-fns"; // or moment
import useApis from "../../apis/useApis";
import appConstant from "../../json/appConstant.json";
import moment from "moment";
import TotalCalls from "./TotalCalls";
import CallTypeChart from "./CallTypeChart";


const Dashboard = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const formatDate = (date) => {
    return date ? format(date, "MMM d, yyyy") : "";
  };
  const { jsonApi, mediaApi } = useApis();

  const [fetchData, setFetchData] = useState([]);
  const [fetchData1, setFetchData1] = useState([]);
  const [limit, setLimit] = useState(10); // Default limit
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchCallHistory = async () => {
    const historyData = await jsonApi(
      appConstant.getCallHistory,
      {
        page,
        limit,
        search: search,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      },
      null,
      true
    );
    if (!historyData.error && Array.isArray(historyData.data.data)) {
      setFetchData(historyData.data.data);
      setTotalData(historyData.data.total);
    } else {
      // setInitError(historyRes.message ? historyRes.message : "Something went wrong");
      // console.error(historyData.message);
    }
  };

  const fetchAllCallHistory = async () => {
    const historyData = await jsonApi(
      appConstant.getAllCallHistory,
      {
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      },
      null,
      true
    );
    if (!historyData.error && Array.isArray(historyData.data.data)) {
      setFetchData1(historyData.data.data);
    } else {
      // setInitError(historyRes.message ? historyRes.message : "Something went wrong");
      // console.error(historyData.message);
    }
  };

  useEffect(() => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDay() - 7);
    setDateRange([lastWeek, today]);
  }, []);

  useEffect(() => {
    fetchCallHistory();
    fetchAllCallHistory();

  }, [page, limit, search, dateRange]);



  const formatTimestamp = (timestamp, formatStr) => {
    if (!timestamp) return "N/A";
    const date = moment(timestamp);
    return date.format(formatStr);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-breadcrumb">
          <div className="row">
            {/* <div className="col-12 align-self-center"> */}
            <div className="col-12 d-flex justify-content-between align-items-center">
              <h3 className="page-title text-truncate text-dark font-weight-medium mb-1 col-6">
                Dashboard
              </h3>

              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                withPortal
                dateFormat="MMM d, yyyy"
                placeholderText="Select the date range....."
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mb-4 text-dark">
            <div className="col-6">
              <h4>Call Summary</h4>
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex flex-fill align-items-center">
                  <div className="icon-wrapper me-2">
                    <Phone />
                  </div>
                  <div>
                    <strong>Total Calls</strong>
                    <br />
                    <span>{totalData}</span>
                  </div>
                </div>
                <div className="d-flex flex-fill align-items-center border-start border-end">
                  <div className="icon-wrapper me-2">
                    <UserCheck />
                  </div>
                  <div>
                    <strong>Total Agents Called</strong>
                    <br />
                    <span>0</span>
                  </div>
                </div>
                <div className="d-flex flex-fill align-items-center">
                  <div className="icon-wrapper me-2">
                    <Award />
                  </div>
                  <div>
                    <strong>Best Performer</strong>
                    <br />
                    <span>Jatin Goel</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h4>Live Activity</h4>
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex flex-fill align-items-center">
                  <div className="icon-wrapper me-2">
                    <Phone />
                  </div>
                  <div>
                    <strong>Live Call</strong>
                    <br />
                    <span>0</span>
                  </div>
                </div>
                <div className="d-flex flex-fill align-items-center border-start border-end">
                  <div className="icon-wrapper me-2">
                    <UserCheck />
                  </div>
                  <div>
                    <strong>Calls in Queue</strong>
                    <br />
                    <span>0</span>
                  </div>
                </div>
                <div className="d-flex flex-fill align-items-center">
                  <div className="icon-wrapper me-2">
                    <Award />
                  </div>
                  <div>
                    <strong>Available Agents</strong>
                    <br />
                    <span>0</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive mt-5">
            <div
              id="default_order_wrapper"
              className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
            >
              <PaginationComponent
                totalData={totalData}
                setPage={setPage}
                limit={limit}
                page={page}
                setLimit={setLimit}
                setSearch={setSearch}
              >
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      id="default_order"
                      className="table table-striped table-bordered display no-wrap text-center w-100 call_history_table dataTable no-footer"
                      role="grid"
                      aria-describedby="default_order_info"
                    >
                      <thead>
                        <tr role="row">
                          <th>S.No</th>
                          <th>Name</th>
                          {/* <th>Agent</th> */}
                          <th>To</th>
                          <th>From</th>
                          <th>Start Time</th>
                          {/* <th>Status</th>
                          <th>Action</th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {fetchData.length > 0 ? (
                          fetchData.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                role="row"
                                className={index % 2 === 0 ? "even" : "odd"}
                              >
                                <td>{(page - 1) * limit + index + 1}</td>
                                <td className="text-left">
                                  {item?.contact[0]?.name || "Unknown User"}
                                </td>
                                <td>
                                  <i className="material-symbols-outlined mr-1">
                                    phone_callback
                                  </i>
                                  {item?.contact[0]?.phone ||
                                    item.phone ||
                                    "N/A"}
                                  {/* </a> */}
                                </td>

                                <td className="sorting_1">
                                  {" "}
                                  {item.userNumber || "N/A"}
                                </td>
                                <td>
                                  {formatTimestamp(
                                    item?.updatedAt,
                                    "dddd, MMM D, yyyy h:mma"
                                  )}
                                </td>
                                {/* <td>
                                  {formatTimestamp(item?.updatedAt, "h:mma")}
                                </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5">No data available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </PaginationComponent>
            </div>
          </div>

          <div className="row mt-4 text-dark">
            <div className="col-6">
              <h4>Call Type</h4>
              <CallTypeChart />
            </div>
            <div className="col-6">
              <h4>Total Calls</h4>

              <TotalCalls fetchData1={fetchData1} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
