import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";

import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { validateName } from "../validation/Validation";
import DragsAndDrop from "../../image/DragsAndDrop";

const AddCoreModule = ({ show, handleClose, init }) => {
  const [logoPreview, setLogoPreview] = useState(null);
  const { jsonApi, mediaApi } = useApis();
  const [error, setError] = useState("");


  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields },
    reset,
    register,
    setValue,
    clearErrors, // Add this to clear errors
  } = useForm({
    defaultValues: {
      name: "",
      logo: null,
    },
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    if (data.logo) {
      formData.append("logo", data.logo);  // data.logo is a FileList, get the first file
    }
    const response = await mediaApi(
      appConstant.createCoreModule,
      null,
      formData,
      null
    ); // Pass token to API call
    if (!response.error) {
      setError("");

      reset();
      setLogoPreview(null);
      handleClose();
      reset();
      await init();
    } else {
      setError(error.message);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="modal fade">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-dark font-weight-medium">
              Create Core Module
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleClose();
                reset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              {error ? (
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-12 mb-3">
                  <label>
                    Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="Name"
                        maxLength={256}
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        {...register("name", {
                          required: "Name is required",
                          validate: (value) => validateName(value, "name"),
                        })}
                      />
                    )}
                  />
                  {errors.name && (
                    <p className="text-danger">{errors.name.message}</p>
                  )}
                </div>
                <div className="col-12 mb-3">
                  <label>
                    Logo <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex justify-content-center w-100">
                  <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  aspect={1 / 1}
                  uploadFile={(x) => {
                    setValue("logo", x);
                    clearErrors("logo");
                  }}
                />
                </div>
                  {/* <Controller
                    name="logo"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          setValue("logo", event.target.files);
                          setLogoPreview(
                            file ? URL.createObjectURL(file) : null
                          ); // Use setValue to update the form state
                          clearErrors("logo"); // Clear the logo validation error
                        }}
                        onBlur={onBlur}
                        ref={ref}
                        className={`form-control ${
                          errors.logo ? "is-invalid" : ""
                        }`}
                        {...register("logo", {
                          required: "Logo is required",
                        })}
                      />
                    )}
                  /> */}
                  {errors.logo && (
                    <p className="text-danger">{errors.logo.message}</p>
                  )}
                  {logoPreview && (
                    <div className="mt-2">
                      <img
                        src={logoPreview}
                        alt="Logo Preview"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}                  
                  
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddCoreModule;
