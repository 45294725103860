import React from "react";
import HierarchyBankingInfo from "./HierarchyFormComponents/HierarchyBankingInfo";
import HierarchyBilling from "./HierarchyFormComponents/HierarchyBilling";
import HierarchyCompanyInfo from "./HierarchyFormComponents/HierarchyCompanyInfo";
import HierarchyCompanySetup from "./HierarchyFormComponents/HierarchyCompanySetup";
import HierarchyContractType from "./HierarchyFormComponents/HierarchyContractType";
import HierarchyCorporatePermissions from "./HierarchyFormComponents/HierarchyCorporatePermissions";
import HierarchyFinished from "./HierarchyFormComponents/HierarchyFinished";
import HierarchyProductsOffered from "./HierarchyFormComponents/HierarchyProductsOffered";
import HierarchyProductsStates from "./HierarchyFormComponents/HierarchyProductsStates";
import HierarchyUserInfo from "./HierarchyFormComponents/HierarchyUserInfo";
import HierarchyUserPermissions from "./HierarchyFormComponents/HierarchyUserPermissions";
import useApis from "./../../apis/useApis";
const HierarchyIndex = ({
  data,
  setData,
  index,
  setIndex,
  submitForm,
  saveAndBackRef,
  saveAndExitRef,
  formRef,
  UserInfo,
}) => {
  if (data.typeOfIndustry === "Insurance") {
    if (index === 1) {
      return (
        <HierarchyCompanySetup
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 2) {
      return (
        <HierarchyCompanyInfo
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 3) {
      return (
        <HierarchyProductsOffered
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 4) {
      return (
        <HierarchyProductsStates
          formRef={formRef}
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 5) {
      return (
        <HierarchyCorporatePermissions
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 6) {
      return (
        <HierarchyUserInfo
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 7) {
      return (
        <HierarchyUserPermissions
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 8) {
      return (
        <HierarchyContractType
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 9) {
      return (
        <HierarchyBankingInfo
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else if (index === 10) {
      return (
        <HierarchyBilling
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else if (index === 11) {
      return (
        <HierarchyFinished
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else {
      return <></>;
    }
  } else {
    if (index === 1) {
      return (
        <HierarchyCompanySetup
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 2) {
      return (
        <HierarchyCompanyInfo
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 3) {
      return (
        <HierarchyCorporatePermissions
          ref={formRef}
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
        />
      );
    } else if (index === 4) {
      return (
        <HierarchyUserInfo
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else if (index === 5) {
      return (
        <HierarchyUserPermissions
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else if (index === 6) {
      return (
        <HierarchyContractType
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else if (index === 7) {
      return (
        <HierarchyBankingInfo
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else if (index === 8) {
      return (
        <HierarchyBilling
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else if (index === 9) {
      return (
        <HierarchyFinished
          data={data}
          setData={setData}
          index={index}
          setIndex={setIndex}
          submitForm={submitForm}
          saveAndBackRef={saveAndBackRef}
          saveAndExitRef={saveAndExitRef}
          formRef={formRef}
        />
      );
    } else {
      return <></>;
    }
  }
};

export default HierarchyIndex;
