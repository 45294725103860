import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import appConstant from "./../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import {
  validateName,
  validateZipCode,
  validatePhoneNumberWithCountryCode,
  validateURL,
} from "../../../components/validation/Validation";


const HierarchyCompanyInfo = forwardRef((props, ref) => {
  const { jsonApi } = useApis();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    setValue,
    control,
  } = useForm();

  // Expose the handleSubmit method to the parent component
  useImperativeHandle(ref, () => ({
    handleSubmit: (param) =>
      handleSubmit((formData) => props.submitForm({ ...formData }, param))(),
  }));

  const handleUsernameChange = async (e) => {
    console.log(e.target.name);
    if (!(e.target.value.length === 0)) {
      const res = await jsonApi(
        appConstant.checkOldEmailAndUsername,
        null,
        { text: e.target.value, type: e.target.name },
        true
      );
      if (res.error) {
        setError(e.target.name, {
          type: "manual",
          message: res.message,
        });
      } else {
        clearErrors(e.target.name);
      }
    }
  };
  useEffect(() => {
    if (props.data) {
      reset({
        typeOfInsurance: props.data.typeOfInsurance,
        companyName: props.data.companyName,
        contactEmail: props.data.contactEmail,
        typeOfCompany: props.data.typeOfCompany,
        npnNumber: props.data.npnNumber,
        einNumber: props.data.einNumber,
        corporatePhone: props.data.corporatePhone,
        corporateFax: props.data.corporateFax,
        contactExtension: props.data.contactExtension,
        companyUrl: props.data.companyUrl,
        companyAddress: props.data.companyAddress,
        city: props.data.city,
        state: props.data.state,
        zip: props.data.zip,
        contactName: props.data.contactName,
        cellNumber: props.data.cellNumber,
      });
    }
  }, [props.data, reset]);

  return (
    <div className="my-5 w-100">
      {props.data.typeOfInsurance === "Insurance" ? (
        <div className="row align-items-center">
          <label className="col-xl-4 col-md-4 mb-3 font-weight-bold">
            Type of Insurance Hierarchy
          </label>
          <div className="col-xl-9 col-md-8 mb-3 pt-2">
            <div className="custom-radio form-check-inline">
              <input
                type="radio"
                id="InsuranceCompany"
                name="typeOfInsurance"
                className="custom-control-input"
                {...register("typeOfInsurance", {
                  required: "Type of insurance is required",
                })}
                value="InsuranceCompany"
              />
              <label
                className="custom-control-label"
                htmlFor="InsuranceCompany"
              >
                Insurance Company
              </label>
            </div>
            <div className="form-check form-check-inline px-4">or</div>
            <div className="custom-radio form-check-inline">
              <input
                type="radio"
                id="InsuranceBroker"
                name="typeOfInsurance"
                className="custom-control-input"
                {...register("typeOfInsurance", {
                  required: "Type of insurance is required",
                })}
                value="InsuranceBroker"
              />
              <label className="custom-control-label" htmlFor="InsuranceBroker">
                Insurance Broker
              </label>
            </div>
            {errors.typeOfInsurance && (
              <p className="error">{errors.typeOfInsurance.message}</p>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Company Information */}
      <div className="row align-items-center mb-2">
        <label className="col-xl-12 font-weight-bold">
          Company information
        </label>
      </div>
      <div className="row align-items-center">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Company Name
        </label>
        <div className="col-xl-4 col-lg-7 col-md-7 mb-1">
          <input
            type="text"
            className="form-control"
            maxLength={256}
            {...register("companyName", {
              required: "Company name is required",
              validate: (value) => validateName(value, "company name"),
            })}
          />
          {errors.companyName && (
            <p className="text-danger">{errors.companyName.message}</p>
          )}
        </div>
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Contact Email
        </label>
        <div className="col-xl-4 col-lg-7 col-md-7 mb-1">
          <input
            type="text"
            className="form-control"
            maxLength={256}
            {...register("contactEmail", {
              required: "Please enter Email Id",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address",
              },
            })}
            onChange={(e) => {
              handleUsernameChange(e);
              setValue("contactEmail", e.target.value, {
                shouldValidate: true,
              });
            }}
          />
          {errors.contactEmail && (
            <p className="text-danger">{errors.contactEmail.message}</p>
          )}
        </div>
      </div>

      <div className="row align-items-center mt-2 mb-1">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Corporate Phone
        </label>
        <div className="col-xl-4 col-lg-7 col-10 col-md-7 mb-1">
          <Controller
            name="corporatePhone"
            control={control}
            rules={{
              required: "Corporate phone is required",
              validate: validatePhoneNumberWithCountryCode,
            }}
            render={({ field }) => (
              <>
                <PhoneInput
                  country={"eg"}
                  enableSearch={true}
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)}
                />
                {errors.corporatePhone && (
                  <p className="text-danger">{errors.corporatePhone.message}</p>
                )}
              </>
            )}
          />
        </div>
        {/* <PhoneInput
            country={"eg"}
            enableSearch={true}
            value={phone}
            onChange={(phone) => setPhone(phone)}
          />
          {errors.corporatePhone && (
            <p className="text-danger">{errors.corporatePhone.message}</p>
          )} */}

        {/* <input
            type="text"
            maxLength={15}
            className="form-control"
            {...register("corporatePhone", {
              required: "Corporate phone is required",
              validate: validatePhoneNumberWithCountryCode,
            })}
          /> */}

        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Corporate Fax #
        </label>
        <div className="col-xl-4 col-lg-7 col-md-7 my-1 col-form-label">
          {/* <input
            type="text"
            maxLength={15}
            className="form-control"
            {...register("corporateFax", {
              required: "Corporate Fax # is required",
              validate: validatePhoneNumberWithCountryCode,
            })}
          />
          
          {errors.corporateFax && (
            <p className="text-danger">{errors.corporateFax.message}</p>
          )} */}
                    <Controller
            name="corporateFax"
            control={control}
            rules={{
              required: "Corporate Fax is required",
              validate: validatePhoneNumberWithCountryCode,
            }}
            render={({ field }) => (
              <>
                <PhoneInput
                  country={"eg"}
                  enableSearch={true}
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)}
                />
                {errors.corporateFax && (
                  <p className="text-danger">{errors.corporateFax.message}</p>
                )}
              </>
            )}
          />
        </div>
      </div>
      {/* Company URL */}
      <div className="row align-items-center mb-2">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-2 col-form-label">
          Company URL
        </label>
        <div className="col-xl-4 col-lg-7 col-md-7 mb-2">
          <input
            type="text"
            className="form-control"
            maxLength={256}
            {...register("companyUrl", {
              required: "Company URL is required",
              validate: validateURL,
            })}
          />
          {errors.companyUrl && (
            <p className="text-danger">{errors.companyUrl.message}</p>
          )}
        </div>
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Contact Extension
        </label>
        <div className="col-xl-4 col-lg-7 col-md-7 my-1 col-form-label">
          {/* <input
            type="text"
            maxLength={15}
            className="form-control"
            {...register("contactExtension", {
              required: "Contact Extension is required",
              validate: validatePhoneNumberWithCountryCode,
            })}
          /> */}
                    <Controller
            name="contactExtension"
            control={control}
            rules={{
              required: "Corporate Extension is required",
              validate: validatePhoneNumberWithCountryCode,
            }}
            render={({ field }) => (
              <>
                <PhoneInput
                  country={"eg"}
                  enableSearch={true}
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)}
                />
                {errors.contactExtension && (
                  <p className="text-danger">{errors.contactExtension.message}</p>
                )}
              </>
            )}
          />
          {/* {errors.contactExtension && (
            <p className="text-danger">{errors.contactExtension.message}</p>
          )} */}
        </div>
      </div>
      {/* Company Address */}
      <div className="row align-items-center mb-2">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Company Address
        </label>
        <div className="col-xl-10 col-lg-7 col-md-7 mb-1">
          <input
            type="text"
            className="form-control"
            maxLength={256}
            {...register("companyAddress", {
              required: "Company address is required",
            })}
          />
          {errors.companyAddress && (
            <p className="text-danger">{errors.companyAddress.message}</p>
          )}
        </div>
      </div>
      {/* City, State, and Zip */}
      <div className="row align-items-center">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          City
        </label>
        <div className="col-xl-3 col-lg-7 col-md-7 mb-1">
          <input
            type="text"
            className="form-control"
            maxLength={256}
            {...register("city", {
              required: "City is required",
              validate: (value) => validateName(value, "city"),
            })}
          />
          {errors.city && <p className="text-danger">{errors.city.message}</p>}
        </div>
        <label className="col-xl-1 col-lg-2 col-md-2 mb-1 col-form-label">
          State
        </label>
        <div className="col-xl-2 col-lg-3 col-md-3 mb-1">
          <input
            type="text"
            className="form-control"
            maxLength={256}
            {...register("state", {
              required: "State is required",
              validate: (value) => validateName(value, "state"),
            })}
          />
          {errors.state && (
            <p className="text-danger">{errors.state.message}</p>
          )}
        </div>
        <label className="col-xl-1 col-lg-2 col-md-2 mb-1 col-form-label">
          Zip
        </label>
        <div className="col-xl-3 col-lg-3 col-md-3 mb-1">
          <input
            type="text"
            maxLength={5}
            className="form-control"
            {...register("zip", {
              required: "Zip is required",
              validate: validateZipCode,
            })}
          />
          {errors.zip && <p className="text-danger">{errors.zip.message}</p>}
        </div>
      </div>
      {/* Contact Name and Cell Number */}
      <div className="row align-items-center">
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Contact Name
        </label>
        <div className="col-xl-4 col-lg-7 col-md-7 mb-1">
          <input
            type="text"
            className="form-control"
            maxLength={256}
            {...register("contactName", {
              required: "Contact name is required",
            })}
          />
          {errors.contactName && (
            <p className="text-danger">{errors.contactName.message}</p>
          )}
        </div>
        <label className="col-xl-2 col-lg-4 col-md-4 mb-1 col-form-label">
          Cell Number
        </label>
        <div className="col-xl-4 col-lg-7 col-md-7 my-1 col-form-label">
          {/* <input
            type="text"
            maxLength={15}
            className="form-control"
            {...register("cellNumber", {
              required: "Cell number is required",
              validate: validatePhoneNumberWithCountryCode,
            })}
          />
          {errors.cellNumber && (
            <p className="text-danger">{errors.cellNumber.message}</p>
          )} */}
                    <Controller
            name="cellNumber"
            control={control}
            rules={{
              required: "Cell number is required",
              validate: validatePhoneNumberWithCountryCode,
            }}
            render={({ field }) => (
              <>
                <PhoneInput
                  country={"eg"}
                  enableSearch={true}
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)}
                />
                {errors.cellNumber && (
                  <p className="text-danger">{errors.cellNumber.message}</p>
                )}
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
});

export default HierarchyCompanyInfo;
