import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const AddNewPayment = ({ paymentModal, setPaymentModal }) => {
  const handleAddNewPaymentClose = () => {
    setPaymentModal(false);
  };

  return (
    <Modal
      show={paymentModal}
      onHide={handleAddNewPaymentClose}
      id="AddNewPaymentMethodModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title text-dark" id="exampleModalLabel">
            Add New Payment Method
          </h3>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleAddNewPaymentClose}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <div
                className="btn-group m-auto d-table w-100"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 w-sm-50 active"
                >
                  Bank Account
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 w-sm-50"
                >
                  Credit or Debit Card Account
                </button>
              </div>
              <div className="card my-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-8">
                      <label className="text-uppercase h4">Bank Name</label>
                      <h4 className="text-black font-weight-bold text-uppercase mb-4">
                        Citybank
                      </h4>
                      <h3 className="text-black text-uppercase mb-4">
                        <span>****</span>
                        <span>****</span>
                        <span>****</span>
                        <span className="d-inline-block">1234</span>
                      </h3>
                      <h4 className="text-black font-weight-bold text-uppercase">
                        Daniel Williams
                      </h4>
                    </div>
                    <div className="col-sm-4 text-center text-sm-right">
                      {/* <img
                        src="assets/images/bank_icon.svg"
                        className="bank_icon"
                        alt="Bank Icon"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <label>Enter Full Name</label>
                  <input
                    type="text"
                    maxLength={256}
                    className="form-control"
                    defaultValue="Daniel Williams"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label>Enter Bank Name</label>
                  <input
                    type="text"
                    maxLength={256}
                    className="form-control"
                    defaultValue="Citybank"
                  />
                </div>
                <div className="col-sm-6 mb-3 position-relative">
                  <label>Routing Number</label>
                  <span className="digits_number">(9 digits)</span>
                  <input
                    maxLength={9}
                    type="text"
                    className="form-control"
                    defaultValue=""
                  />
                </div>
                <div
                  className="col-sm-6 mb-3 position-relative"
                  id="show_hide_password"
                >
                  <label>Bank Account Number</label>
                  <span className="digits_number">
                    <Link to="" className="text-muted">
                      <i className="fa fa-eye-slash show_pass" />
                    </Link>
                  </span>
                  <input
                    className="form-control ps-5"
                    id="password"
                    name="password"
                    placeholder="Password"
                    required="required"
                    type="password"
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <label>Account Type</label>
                  <select className="form-control">
                    <option>Saving</option>
                    <option>Checking</option>
                  </select>
                </div>
                <div className="col-12 mt-4 mb-5">
                  <button type="button" className="w-100 btn_green">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewPayment;
