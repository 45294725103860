import React, { useEffect, useState,useRef } from "react";
import personalData from "../../../json/personalData.json";
import PaginationComponent from "../../pagination/PaginationComponent";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import { Edit2, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import PersonalFavButton from "./PersonalFavButton";



const AllDataPersonal = ({ onEditChange,deleteUserDetail }) => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");
  const formRef = useRef(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);


  const fetchContactHuman = async () => {
    try {
      const contactHuman = await jsonApi(
        appConstant.getContactPersonal,
        { page, limit, search: search },
        null,
        true
      );

      if (!contactHuman.error) {
        setAllContactDetails(contactHuman.data.data || []); // Update state with actual data
        setTotalData(contactHuman.data.total);
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);

  const navigate = useNavigate();

  const handleEditClick = (id) => {
    navigate(
      `/business-contact/edit-personal-contact/contact-profile?id=${id}`
    );
    if (onEditChange) {
      onEditChange(true); 
    }
   
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDeleteClick=async(id)=>{
    await deleteUserDetail(id);
    fetchContactHuman();
  }

  
const formattedDate =(timestamp, formatDob)=>{
  if(!timestamp) return "N/A";
  const dob=moment(timestamp)
  return dob.format(formatDob)
}


  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <div className="row ">{/*/Col*/}</div>
              {/*/Row*/}

              {/*/Row*/}
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-12 mb-2">
                            <table
                              id="default_order"
                              className="table table-hover table-striped display no-wrap w-100"
                            >
                              <thead>
                                <tr>
                                  {personalData.headers1
                                    // .filter((header) => header !== "Edit")
                                    // .concat(["Employment Level"])
                                    .map((header, index) => (
                                      <th scope="col" key={index}>
                                        {header}
                                      </th>
                                    ))}
                                </tr>
                              </thead>
                              <tbody>
                                {allContactDetails &&
                                allContactDetails.length > 0 ? (
                                  allContactDetails.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {(page - 1) * limit + index + 1}
                                          <PersonalFavButton item={item} init={fetchContactHuman}/>
                                        </td>
                                        <td>{item.contactDetail?.name || "No Data"}</td>
                                        <td>
                                          {item?.contactDetail?.cellNo ||
                                            "No Data"}
                                        </td>
                                        <td>
                                          {item?.contactDetail?.personalEmail ||
                                            "No Data"}
                                        </td>
                                        <td>
                                          {formattedDate(item?.contactDetail?.dob, "DD/MM/YYYY") ||
                                            "No Data"}
                                        </td>
                                        <td><Edit2
                                          style={{ cursor: "pointer" }}
                                          className="ml-1"
                                          onClick={() =>
                                            handleEditClick(item._id)
                                            
                                          }
                                        /></td>

                                        <td><Trash
                                          style={{ cursor: "pointer" }}
                                          className="ml-1"
                                          onClick={() => handleDeleteClick(item._id)}

                                         
                                            
                                       
                                        /></td>
                                        
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="5">No data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllDataPersonal;
