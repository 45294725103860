import React, { useEffect } from "react";
import { Search } from "react-feather";
 
const SearchForm = ({ search, setSearch, onSearch  }) => {


  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchClick =()=>{
    onSearch();
  }


  return (
    <div className="db-search d-none d-lg-inline-block">
      <form>
        <div className="row customize-input">
          <div>
            <input
              className="form-control custom-shadow custom-radius-left bg-light border border-dark border-1 "
              type="search"
              placeholder="Database Search" 
              aria-label="Search"
              maxLength={256}
              value={search }
              onChange={handleSearchChange}
            />
          </div>
          <div className="input-prepend">
            <select className="form-control custom-shadow custom-radius-right bg-light border border-dark border-1"
            >
              <option selected="">Search Criteria</option>
              <option>All</option>
              <option>Last Name</option>
              <option>First Name</option>
              <option>Business Name</option>
              <option>Phone Number</option>
              <option>Email</option>
              <option>Business Type</option>
            </select>
            <i
              className="form-control-icon-arrow"
              data-feather="chevron-down"
            />
            <i className="form-control-icon" data-feather="search" />
          </div>

          <div className="form-control-icon" onClick={handleSearchClick }><Search/></div>       
           </div>
      </form>
      
    </div>

  );
};

export default SearchForm;
