import React, { useState } from "react";
import { Phone, PhoneOff } from "react-feather";
const DialPad = ({
  makeCall,
  picked,
  setPicked,
  handleEndCall,
  inCall,
  callDuration,
}) => {
  const buttonNumbers = [
    [1, ""],
    [2, ""],
    [3, ""],
    [4, ""],
    [5, ""],
    [6, ""],
    [7, ""],
    [8, ""],
    [9, ""],
    ["*", ""],
    [0, ""],
    ["+", ""],
  ];

  const addNumbers = (number) => {
    if (picked === "Call Ended") {
      setPicked([number]);
    } else {
      setPicked([...picked, number]);
    }
  };
  const deleteNumber = () => {
    setPicked((picked) => picked.slice(0, -1));
  };

  const initiateCall = () => {
    makeCall(Array.isArray(picked) ? picked.join("") : null);
  };
  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const endCall = () => {
    handleEndCall();
    setPicked("Call Ended");
  };
  return (
    <div className="col-lg-4 mb-3 align-self-stretch">
      <div className="card h-100">
        <div className="card-body dial_call">
          <h4 className="page-title text-truncate text-dark font-weight-medium mb-3">
            Call as
          </h4>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="form-control">
                <h3 id="showPicked">{picked}</h3>
              </div>
            </div>
            <div className="col-12 mb-3">
              {inCall ? (
                <div>
                  <div className="col-12 text-center">
                    Call Duration: {formatDuration(callDuration)}
                  </div>
                  <div className="col-12 text-center">
                    <button onClick={endCall} className="btn btn-danger">
                      <PhoneOff />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 text-center">
                    <div id="btns">
                      {buttonNumbers.map((numbers, index) => (
                        <button
                          key={index}
                          onClick={() => addNumbers(numbers[0])}
                          className="btn btn-light"
                          type="button"
                        >
                          {numbers[0]}
                          <p>{numbers[1]}</p>
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <div className="action_btn_call">
                      <button
                        onClick={initiateCall}
                        className="btn btn-success"
                      >
                        <Phone />
                      </button>

                      <button onClick={deleteNumber} className="btn btn-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                          className="back_space"
                        >
                          <path d="M576 64H205.3A64 64 0 0 0 160 82.8L9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3L160 429.3c12 12 28.3 18.8 45.3 18.8H576c35.4 0 64-28.7 64-64V128c0-35.4-28.7-64-64-64zm-84.7 254.1c6.3 6.3 6.3 16.4 0 22.6l-22.6 22.6c-6.3 6.3-16.4 6.3-22.6 0L384 301.3l-62.1 62.1c-6.3 6.3-16.4 6.3-22.6 0l-22.6-22.6c-6.3-6.3-6.3-16.4 0-22.6L338.8 256l-62.1-62.1c-6.3-6.3-6.3-16.4 0-22.6l22.6-22.6c6.3-6.3 16.4-6.3 22.6 0L384 210.8l62.1-62.1c6.3-6.3 16.4-6.3 22.6 0l22.6 22.6c6.3 6.3 6.3 16.4 0 22.6L429.3 256l62.1 62.1z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialPad;
