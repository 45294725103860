import React, { useEffect, useState } from "react";
import SearchForm from "./SearchForm";
import AddBusinessPersonalContact from "./AddBusinessPersonalContact";
import { Link, Outlet, useLocation } from "react-router-dom";
import SearchBased from "./SearchBased";

const BusinessContact = () => {
  const [search, setSearch] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Reset search mode when location changes

    setIsSearching(false);
  }, [location]);

  const handleSearch = () => {
    setIsSearching(true);
  };

  useEffect(() => {
    if (search) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  }, [search]);

  return (
    <div className="page-wrapper">
      {/* Bread crumb and right sidebar toggle */}
      <div className="page-breadcrumb">
        <div className="row">
          <SearchForm
            search={search}
            setSearch={setSearch}
            searchCriteria={searchCriteria}
            setSearchCriteria={setSearchCriteria}
            onSearch={handleSearch} // Pass the handleSearch function
          />
          <AddBusinessPersonalContact />
        </div>
      </div>

      {isSearching ? (
        <SearchBased
          search={search}
          searchCriteria={searchCriteria}
          setSearch={setSearch}
          isSearching={isSearching}
        />
      ) : (
        <Outlet />
      )}
      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <Link to="/">Digital Company</Link>.
      </footer>
    </div>
  );
};

export default BusinessContact;
