import React from "react";

const Loader = ({ open }) => {
  const loaderStyle = {
    position: "fixed",
    top: "calc(50% - 40px)",
    left: "calc(50% - 40px)",
    display: open ? "block" : "none",
    zIndex: 1000000000,
  };

  const spinnerStyle = {
    border: "10px solid #f3f3f3",
    borderTop: "10px solid #3498db",
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    animation: "spin 1s linear infinite",
  };

  const keyframesStyle = `
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  // Create a style element to add keyframes
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = keyframesStyle;
  document.head.appendChild(styleSheet);

  return (
    <div style={loaderStyle}>
      <div style={spinnerStyle}></div>
    </div>
  );
};

export default Loader;
