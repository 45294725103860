import React, { useState } from "react";

const PaginationComponent = ({
  totalData,
  page,
  limit,
  setPage,
  setLimit,
  children,
  setSearch,
}) => {


  const totalPages = Math.ceil(totalData / limit);

  const [pageRangeStart, setPageRangeStart] = useState(1);


  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      updatePageRange(newPage)
    }
  };

  const updatePageRange=(currentPage)=>{
    if(currentPage>=pageRangeStart+5){
      setPageRangeStart(pageRangeStart+5);
    }else if (currentPage<pageRangeStart){
      setPageRangeStart(pageRangeStart-5>0 ? pageRangeStart-5 :1)
    }
  }

  const getVisiblePages=()=>{
    const pages=[];
    const end=Math.min(pageRangeStart+4,totalPages)
    for(let i=pageRangeStart;i<=end;i++){
      pages.push(i)
    }
    return pages;
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length">
            <label className="form-label">
              Show
              <select
                className="form-select form-select-sm ms-2"
                value={limit}
                onChange={(e) => setLimit(Number(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              entries
            </label>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_filter">
            <label className="form-label">
              Search:
              <input
                type="search"
                maxLength={256}
                className="form-control form-control-sm ms-2"
                onChange={(e) => setSearch(e.target.value)}
              />
            </label>
          </div>
        </div>
      </div>

      {children}

      <div className="row">
        <div className="col-sm-12 col-md-5">
          <div className="dataTables_info" role="status" aria-live="polite">
            Showing {(page - 1) * limit + 1} to{" "}
            {Math.min(page * limit, totalData)} of {totalData} entries
          </div>
        </div>
        <div className="col-sm-12 col-md-7">
          <div className="dataTables_paginate">
            <ul className="pagination justify-content-end">
              <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  Previous
                </button>
              </li>
              {/* Render page numbers dynamically */}
              {/* {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${page === index + 1 ? "active" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))} */}
              {getVisiblePages().map((p)=>(
                <li
                key={p}
                className={`page-item ${page === p  ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(p)}
                >
                  {p}
                </button>
              </li>
              ))}
              <li
                className={`page-item ${page === totalPages ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginationComponent;





// import React, { useEffect, useState } from "react";
// import { Pagination } from "react-bootstrap";

// const PaginationComponent = ({
//   totalData,
//   page,
//   limit,
//   setPage,
//   setLimit,
//   children,
//   setSearch,
// }) => {
//   const [items, setItems] = useState(false);

//   useEffect(() => {
//     const totalPages =
//       Math.trunc(totalData / limit) + (totalData % limit === 0 ? +0 : +1) + 1;
//     console.log(totalPages);

//     let item = [];

//     // Previous Button
//     item.push(
//       <Pagination.Prev
//         key="prev"
//         disabled={page === 1}
//         onClick={() => setPage(page - 1)}
//       />
//     );

//     // Numbered Pages
//     // for (let number = 1; number <= totalPages; number++) {
//     //   item.push(
//     //     <Pagination.Item
//     //       key={number}
//     //       active={number === page}
//     //       onClick={() => setPage(number)}
//     //     >
//     //       {number}
//     //     </Pagination.Item>
//     //   );
//     // }

//     // Numbered Pages
//     if (totalPages <= 5) {
//       for (let number = 1; number <= totalPages; number++)
//         item.push(
//           <Pagination.Item
//             key={number}
//             active={number === page}
//             onClick={() => setPage(number)}
//           >
//             {number}
//           </Pagination.Item>
//         );
//     }

//     // // Calculating the starting and ending page numbers for the pagination display
//     // else {
//     //   let startPage = Math.max(1, page - 2);
//     //   let endPage = Math.min(totalPages, startPage + 4);

//       // if (page <= 2) {
//       //   endPage = 5; //Always showing 5 pages in the begining
//       //   // endPage = Math.min(5, totalPages); // Ensure it does not exceed totalPages

//       // } else if (page > totalPages - 2) {
//       //   startPage = totalPages - 4; //Displaying 5 Pages at the end
//       // }

//         // Adjust the start and end page to ensure 5 pages are shown
//   // if (page <= 2) {
//   //   endPage = Math.min(5, totalPages);
//   // } else if (page > totalPages - 2) {
//   //   startPage = Math.max(totalPages - 4, 1);
//   // }



//       for (let number = startPage; number <= endPage; number++)
//         item.push(
//           <Pagination.Item
//             key={number}
//             active={number === page}
//             onClick={() => setPage(number)}
//           >
//             {number}
//           </Pagination.Item>
//         );
//     }

//     // Next Button
//     item.push(
//       <Pagination.Next
//         key="next"
//         // disabled={page === totalPages - 1 || totalPages === 0}
//         disabled={page === totalPages} // Adjusted here

//         onClick={() => setPage(page + 1)}
//       />
//     );

//     setItems(item);
//   }, [totalData, page, limit, setPage]);

//   return (
//     <>
//       <div class="row">
//         <div class="col-sm-12 col-md-6">
//           <div class="dataTables_length" id="default_order_length">
//             <label>
//               Show
//               <select
//                 name="default_order_length"
//                 aria-controls="default_order"
//                 class="form-control form-control-sm"
//                 value={limit}
//                 onChange={(e) => setLimit(Number(e.target.value))}
//               >
//                 <option value={10}>10</option>
//                 <option value={25}>25</option>
//                 <option value={50}>50</option>
//                 <option value={100}>100</option>
//               </select>{" "}
//               entries
//             </label>
//           </div>
//         </div>
//         <div class="col-sm-12 col-md-6">
//           <div id="default_order_filter" class="dataTables_filter">
//             <label>
//               Search:
//               <input
//                 type="search"
//                 maxLength={256}
//                 class="form-control form-control-sm"
//                 placeholder=""
//                 aria-controls="default_order"
//                 // value={search}
//                 onChange={(e) => setSearch(e.target.value)}
//               />
//             </label>
//           </div>
//         </div>
//       </div>
//       {children}
//       <div class="row">
//         <div class="col-sm-12 col-md-5">
//           <div
//             class="dataTables_info"
//             id="default_order_info"
//             role="status"
//             aria-live="polite"
//           >
//             Showing {(page - 1) * limit + 1} to{" "}
//             {Math.min(page * limit, totalData)} of {totalData} entries
//             {/* Showing 1 to 10 of 50 entries */}
//           </div>
//         </div>
//         <div class="col-sm-12 col-md-7">
//           <div
//             class="dataTables_paginate paging_simple_numbers"
//             id="default_order_paginate"
//           >
//             <ul class="pagination">
//               <Pagination>{items}</Pagination>
//             </ul>
//             <style>
//               {`
//           .pagination .page-item.active .visually-hidden {
//             display: none;
//           }
//         `}
//             </style>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default PaginationComponent;
