import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Edit2, Trash } from "react-feather";
import ImageComponent from "../../media/ImageComponent";
import ModelUi from "../model/ModelUi";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  validateName,
  validatePhoneNumberWithCountryCode,
} from "../validation/Validation";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import DragsAndDrop from "../../image/DragsAndDrop";
import DeleteContact from "./DeleteContact";

const Contact = ({
  contact,
  setPicked,
  picked,
  makeCall,
  setSearchContact,
  total,
  getMoreContact,
  fetchContact,
  limit,
}) => {
  const [editContactModel, setEditContactModel] = useState(false);
  const [currentContactData, setCurrentContactData] = useState(null);
  const [editThumbnailUrl, setEditThumbnailUrl] = useState("");
  const [editThumbnail, setEditThumbnail] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const { mediaApi, jsonApi } = useApis();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      logo: [],
    },
  });

  const openDeleteModel = (data) => {
    setDeleteData(data); // Save the module data for deletion
    setDeleteModal(true);
  };

  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const deleteContactHandler = async () => {
    if (deleteData) {
      console.log("assssssss",deleteData);
      const result = await jsonApi(
        appConstant.deleteContact,
        { id: deleteData },
        null,
        null
      );
      if (!result.error) {
      fetchContact();
      } else {
        console.error("Failed to delete core module:", result.data);
      }
      closeDeleteModel();
    }
  };

  useEffect(() => {
    if (currentContactData) {
      reset({
        name: currentContactData.name,
        phone: currentContactData.phone,
        logo: [],
      });
      setEditThumbnailUrl(currentContactData.logo);
    }
  }, [currentContactData, reset]);

  const editContactModelHandleClose = () => {
    setEditContactModel(false);
    setEditThumbnailUrl("");
    setEditThumbnail(null);
  };

  const editContactModelHandleOpen = (contact) => {
    setCurrentContactData(contact);
    setEditContactModel(true);
  };

  const onSubmit = async (data) => {
    try {
      // Update contact image
      if (data?.logo?.name) {
        const formData = new FormData();
        formData.append("logo", data.logo);
        const responseImage = await mediaApi(
          appConstant.editContactImage,
          { id: currentContactData._id },
          formData,
          null
        );
        if (responseImage.error) {
          throw new Error(responseImage.error.message);
        }
      }

      // Update contact details
      const response = await jsonApi(
        appConstant.editContact,
        { id: currentContactData._id },
        { name: data.name, phone: data.phone },
        null
      );
      if (response.error) {
        throw new Error(response.error.message);
      }
      await fetchContact();
      editContactModelHandleClose();

      setSearchContact((prev) => prev); // Trigger re-render or state update
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="col-lg-4 col-md-6 mb-3 align-self-stretch">
        <div className="card h-100">
          <div className="card-body">
            <h4 className="page-title text-truncate text-dark font-weight-medium mb-3">
              Contact
            </h4>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="input-group">
                  <input
                    className="form-control border-right-0 border form-rounded"
                    type="text"
                    maxLength={256}
                    id="SearchContacts"
                    placeholder="Search Contacts"
                    onChange={(e) => setSearchContact(e.target.value)}
                  />
                  <span className="input-group-append">
                    <div className="input-group-text bg-transparent form-rounded">
                      <i className="fa fa-search" />
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ul className="call_history_list" id="call_contact_list">
                  <InfiniteScroll
                    dataLength={contact.length}
                    next={getMoreContact}
                    hasMore={total >= limit}
                    scrollableTarget="call_contact_list"
                    loader={<h4>Loading...</h4>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={fetchContact}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8595; Pull down to refresh
                      </h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: "center" }}>
                        &#8593; Release to refresh
                      </h3>
                    }
                  >
                    {contact.map((item) => (
                      <li key={item._id}>
                        <span className="left_icon">
                          <ImageComponent src={item.logo} />
                        </span>
                        <span className="call_number">
                          {item.name}
                          <a
                            href={`tel:${item.phone}`}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            data-original-title={`Call ${item.phone}`}
                          >
                            <small>{item.phone}</small>
                          </a>
                        </span>
                        <span
                          className="left_icon"
                          onClick={() => editContactModelHandleOpen(item)}
                        >
                          <Edit2 className="feather-icon" />
                        </span>
                        <span
                          className="left_icon"
                          onClick={() =>
                            openDeleteModel(item._id)
                          } // Pass module ID to delete handler
                        >
                          <Trash className="feather-icon" />
                        </span>
                        <span
                          onClick={() => makeCall(item.phone)}
                          className="right_icon"
                        >
                          <i className="material-symbols-outlined">call</i>
                        </span>                      
                      </li>
                    ))}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteContact
          show={deleteModal}
          handleClose={closeDeleteModel}
          deleteFunction={deleteContactHandler} // Call delete function
        />
      <ModelUi
        show={editContactModel}
        handleClose={editContactModelHandleClose}
        title="Edit Contact"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 mb-3">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      maxLength={256}
                      placeholder="Name"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      {...register("name", {
                        required: "Name is required",
                        validate: (value) => validateName(value, "name"),
                      })}
                    />
                  )}
                />
                {errors.name && (
                  <p className="text-danger">{errors.name.message}</p>
                )}
              </div>
              <div className="col-12 mb-3">
                {/* <label>
                  Phone <span className="text-danger">*</span>
                </label> */}
                {/* <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      placeholder="Phone"
                      maxLength={15}
                      className={`form-control ${
                        errors.phone ? "is-invalid" : ""
                      }`}
                      {...register("phone", {
                        required: "Phone is required",
                        validate: validatePhoneNumberWithCountryCode,
                      })}
                    />
                  )}
                /> */}
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone is required",
                  }}
                  render={({ field }) => (
                    <>
                      <PhoneInput
                        country={"eg"}
                        enableSearch={true}
                        value={field.value}
                        onChange={(phone) => field.onChange(phone)}
                      />
                    </>
                  )}
                />
                {errors.phone && (
                  <p className="text-danger">{errors.phone.message}</p>
                )}
              </div>
              <div className="col-12 mb-3">
                <label>
                  Logo <span className="text-danger">*</span>
                </label>
                <div className="d-flex justify-content-center w-100">
                  <DragsAndDrop
                    imgKey={currentContactData?.logo}
                    heading="Upload Image"
                    inputName="Image"
                    aspect={1 / 1}
                    uploadFile={(x) => {
                      setValue("logo", x);
                    }}
                  />
                </div>
                {/* <Controller
                  name="logo"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        setEditThumbnail(file);
                        const reader = new FileReader();
                        reader.onload = () => {
                          setEditThumbnailUrl(reader.result);
                        };
                        reader.readAsDataURL(file);
                        setValue("logo", event.target.files);
                      }}
                      onBlur={onBlur}
                      ref={ref}
                      className={`form-control ${
                        errors.logo ? "is-invalid" : ""
                      }`}
                    />
                  )}
                /> */}
                {/* {editThumbnail ? (
                  <img
                    className="w-100"
                    src={editThumbnailUrl}
                    alt="Thumbnail Preview"
                  />
                ) : (
                  <ImageComponent className="w-100" src={editThumbnailUrl} />
                )} */}
                {errors.logo && <p className="error">{errors.logo.message}</p>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </form>
      </ModelUi>
    </>
  );
};

export default Contact;
