import React, { useEffect, useState } from "react";
import personalData from "../../../json/personalData.json";
import PaginationComponent from "../../pagination/PaginationComponent";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import moment from 'moment';



const PersonalFavourites = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [allContactDetails, setAllContactDetails] = useState([]);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const fav = true;


  const fetchContactHuman = async () => {
    try {
      const contactHuman = await jsonApi(
        appConstant.getContactPersonal,
        { page, limit, fav, search: search},
        null,
        true
      );

      if (!contactHuman.error) {
        setAllContactDetails(contactHuman.data.data || []); // Update state with actual data
        setTotalData(contactHuman.data.total);
      } else {
        setError(
          contactHuman.message ? contactHuman.message : "No data received"
        );
      }
    } catch (error) {
      setError("An unexpected error occurred");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchContactHuman();
  }, [page, limit, search]);


const formattedDate =(timestamp, formatDob)=>{
  if(!timestamp) return "N/A";
  const dob=moment(timestamp)
  return dob.format(formatDob)
}

  return (
    <div className="container-fluid">
      {/* Start Page Content */}
      {/* basic table */}
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
            <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Personal Favorites
              </h4>
              <div className="row ">{/*/Col*/}</div>
              {/*/Row*/}

              {/*/Row*/}
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-12 mb-2">
                            <table
                              id="default_order"
                              className="table table-hover table-striped display no-wrap w-100"
                            >
                              <thead>
                                <tr>
                                  {personalData.headers2
                                    // .filter((header) => header !== "Edit")
                                    // .concat(["Employment Level"])
                                    .map((header, index) => (
                                      <th scope="col" key={index}>
                                        {header}
                                      </th>
                                    ))}
                                </tr>
                              </thead>
                              <tbody>
                                {allContactDetails &&
                                allContactDetails.length > 0 ? (
                                  allContactDetails.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {(page - 1) * limit + index + 1}
                                        </td>
                                        <td>{item.contactDetail?.name || "No Data"}</td>
                                        <td>
                                          {item?.contactDetail?.cellNo ||
                                            "No Data"}
                                        </td>
                                        <td>
                                          {item?.contactDetail?.personalEmail ||
                                            "No Data"}
                                        </td>
                                        <td>
                                          {formattedDate(item?.contactDetail?.dob, "DD/MM/YYYY") ||
                                            "No Data"}
                                        </td>
                             
                                    
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="5">No data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              {/*/Row*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalFavourites;
