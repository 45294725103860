import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { validateName, validateDescription } from "../validation/Validation";

const AddNotes = () => {
  const { jsonApi } = useApis();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Initialize useForm with validation schema
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await jsonApi(appConstant.createNote, null, data, null);
      if (!response.error) {
        // Reset form fields to empty
        reset({
          noteName: "",
          noteDescription: "",
        });
        setSuccessMessage("Note Created Successfully");
        setError("");
      } else {
        setError(response.message || "Failed to create note.");
        setSuccessMessage(""); // Clear success message if there's an error
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError("An unexpected error occurred.");
      setSuccessMessage(""); // Clear success message if there's an error
    }
  };

  // Automatically clear the success message after 3 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000); // Adjust time (in milliseconds) as needed

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [successMessage]);

  return (
    <div className="page-wrapper">
      <div className="page-breadcrumb">
        <div className="row">
          <div className="db-search d-none d-lg-inline-block"></div>
          <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
            Add New Note
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Note Name
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="noteName"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            placeholder="Note Name"
                            maxLength={256}
                            className={`form-control ${
                              errors.noteName ? "is-invalid" : ""
                            }`}
                            {...register("noteName", {
                              required: "Note Name is required",
                              validate: (value) =>
                                validateName(value, "note name"),
                            })}
                          />
                        )}
                      />
                      {errors.noteName && (
                        <p className="text-danger">{errors.noteName.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-2 col-md-3 col-sm-4">
                      Note Description
                    </label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <Controller
                        name="noteDescription"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            placeholder="Note Description"
                            className={`form-control ${
                              errors.noteDescription ? "is-invalid" : ""
                            }`}
                            maxLength={1024}
                            {...register("noteDescription", {
                              required: "Note Description is required",
                              validate: validateDescription,
                            })}
                          />
                        )}
                      />
                      {errors.noteDescription && (
                        <p className="text-danger">
                          {errors.noteDescription.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-2 col-md-3 col-sm-4">&nbsp;</label>
                    <div className="col-lg-10 col-md-9 col-sm-8">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-rounded px-4"
                      >
                        Create Note
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              title="Ads"
            />
          </div>
        </div>
      </div>
      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <a href="#">Digital Company</a>.
      </footer>
    </div>
  );
};

export default AddNotes;
