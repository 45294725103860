import React, { useState, useEffect } from "react";
import AddCoreModule from "./AddCoreModule";
import useAuthApis from "../../apis/useAuthApis";
import DeleteCoreModule from "./DeleteCoreModule";
import EditCoreModule from "./EditCoreModule";
import ImageComponent from "../../media/ImageComponent";
import PaginationComponent from "../pagination/PaginationComponent";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import { Link } from "react-router-dom";

const CoreModule = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [initError, setInitError] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [currentCoreData, setCurrentCoreData] = useState({});
  const [limit, setLimit] = useState(10); // Default limit
  const [search, setSearch] = useState("");

  const { jsonApi, mediaApi } = useApis();

  const handleShow = () => {
    setShow(true);
  };

  const openDeleteModel = (data) => {
    setDeleteData(data); // Save the module data for deletion
    setDeleteModal(true);
  };

  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const deleteCoreModuleHandler = async () => {
    if (deleteData) {
      const result = await jsonApi(
        appConstant.deleteCoreModule,
        { id: deleteData },
        null,
        null
      );
      if (!result.error) {
        init();
      } else {
        console.error("Failed to delete core module:", result.data);
      }
      closeDeleteModel();
    }
  };
  const handleEditCoreModule = async (module) => {
    setCurrentCoreData(module);
    setShowModal(true);
  };

  // const hanldeLimitChange = (e) => {
  //   setLimit(parseInt(e.target.value));
  //   setPage(1);
  // };

  const init = async () => {
    const res = await jsonApi(
      appConstant.getCoreModule,
      { page, limit, search: search },
      null,
      true
    );
    if (!res.error) {
      setData(res.data.data);
      setTotalData(res.data.total);
      setInitError("");
    } else {
      setInitError(res.message ? res.message : "Something went wrong");
      console.error(res.message);
    }
  };
  useEffect(() => {
    init();
  }, [page, limit, search]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-10 align-self-center">
              <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                Core Module
                {/* {totalData ? `(${totalData})` : ""} Data */}
              </h3>
            </div>
            <div className="col-2 align-self-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleShow}
              >
                Create
              </button>
            </div>
          </div>
        </div>
        {/* Container fluid  */}

        <div className="container-fluid">
          {initError ? (
            <div class="alert alert-danger" role="alert">
              {initError}
            </div>
          ) : (
            <></>
          )}
          {/* Start Page Content */}
          {/* basic table */}
          <div className="row">
            <div className="col-xl-11">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="table-responsive">
                        <div
                          id="default_order_wrapper"
                          className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                        >
                          <PaginationComponent
                            totalData={totalData}
                            page={page}
                            limit={limit}
                            setPage={setPage}
                            setLimit={setLimit}
                            setSearch={setSearch}
                          >
                            <div class="row">
                              <div class="col-sm-12">
                                <table
                                  id="default_order"
                                  className="table table-striped table-bordered display no-wrap text-center w-100 call_history_table dataTable no-footer"
                                  role="grid"
                                  aria-describedby="default_order_info"
                                >
                                  <thead>
                                    <tr>
                                      <th>S No.</th>
                                      <th className="text-left">Name</th>
                                      <th>Logo</th>
                                      <th>Edit</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.map((module, index) => (
                                      <tr key={module.id || index}>
                                        {/* <td>{index + 1}</td> */}
                                        <td>
                                          {(page - 1) * limit + index + 1}
                                        </td>
                                        <td className="text-left">
                                          {module.name}
                                        </td>
                                        <td>
                                          <ImageComponent
                                            className="user_photo mr-1 h-100"
                                            src={module.logo}
                                          />
                                        </td>
                                        <td>
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                            // onClick={handleShowModal}
                                            onClick={() => {
                                              handleEditCoreModule(module);
                                            }}
                                          >
                                            Edit
                                          </button>
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() =>
                                              openDeleteModel(module._id)
                                            } // Pass module ID to delete handler
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </PaginationComponent>
                        </div>
                      </div>
                    </div>
                    {/*/Col*/}
                  </div>
                  {/*/Row*/}
                </div>
                {/*/Card Body*/}
              </div>
              {/*/Card*/}
            </div>
            <div className="col-xl-1 d-none d-xl-block">
              <img
                src="/assets/images/banner/ads.jpg"
                className="mx-auto d-block img-fluid"
                alt=""
                title="Ads"
              />
            </div>
          </div>
        </div>
        {/* End Container fluid  */}
        {/* footer */}
        <footer className="footer text-center text-muted">
          All Rights Reserved by Digital Company. Designed and Developed by{" "}
          <Link to="/">Digital Company</Link>.
        </footer>
        {/* End footer */}
        <AddCoreModule
          show={show}
          handleClose={() => {
            setShow(false);
          }}
          init={init}
        />
        <DeleteCoreModule
          show={deleteModal}
          handleClose={closeDeleteModel}
          deleteFunction={deleteCoreModuleHandler} // Call delete function
        />
        <EditCoreModule
          show={showModal}
          handleClose={() => setShowModal(false)}
          currentCoreData={currentCoreData}
          init={init}
        />
      </div>
    </>
  );
};

export default CoreModule;
