import React, { useEffect, useState } from "react";
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";
import { Link } from "react-router-dom";
import DeleteNote from "./DeleteNote";
import PaginationComponent from "../pagination/PaginationComponent"; // Import PaginationComponent

const ShowNotes = ({ usePageWrapper = true }) => {
  const [data, setData] = useState([]);
  const [initError, setInitError] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [search, setSearch] = useState("");

  const { jsonApi } = useApis();

  useEffect(() => {
    init();
  }, [page, limit, search]);

  const init = async () => {
    const res = await jsonApi(
      appConstant.getNote,
      { page, limit, search: search },
      null,
      null
    );
    if (!res.error) {
      console.log(res)
      setData(res.data.data);
      setTotalData(res.data.total);
      setInitError("");
    } else {
      setInitError(res.message ? res.message : "Something went wrong");
      console.error(res.message);
    }
  };

  const openDeleteModel = (data) => {
    setDeleteData(data); // Save the note data for deletion
    setDeleteModal(true);
  };

  const closeDeleteModel = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const deleteNoteHandler = async () => {
    if (deleteData) {
      const result = await jsonApi(
        appConstant.deleteNote,
        { id: deleteData },
        null,
        null
      );
      if (!result.error) {
        init();
      } else {
        console.error("Failed to delete note:", result.data);
      }
      closeDeleteModel();
    }
  };

  return (
    <div className={usePageWrapper ? "page-wrapper" : ""}>
      <div className="page-breadcrumb">
        <div className="row">
          <div className="col-12 align-self-center">
            <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
              List Of Notes
            </h3>
          </div>
        </div>
      </div>
      {/* Container fluid  */}
      <div className="container-fluid">
        {initError && (
          <div className="alert alert-danger" role="alert">
            {initError}
          </div>
        )}
        {/* Start Page Content */}
        <div className="row">
          <div className="col-xl-11">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="table-responsive">
                      <div className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <PaginationComponent
                          totalData={totalData}
                          page={page}
                          limit={limit}
                          setPage={setPage}
                          setLimit={setLimit}
                          setSearch={setSearch}
                        >
                          <div className="row">
                            <div className="col-sm-12">
                              <table
                                id="default_order"
                                className="table table-striped table-bordered display no-wrap text-center w-100 call_history_table dataTable no-footer"
                                role="grid"
                                aria-describedby="default_order_info"
                              >
                                <thead>
                                  <tr role="row">
                                    <th>S. No.</th>
                                    <th className="text-left">Note Name</th>
                                    <th>Note Description</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.map((note, index) => (
                                    <tr key={note.id || index}>
                                      <td>{(page - 1) * limit + index + 1}</td>
                                      <td className="text-left">
                                        {note.noteName}
                                      </td>
                                      <td
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          wordBreak: "break-word",
                                          textAlign: "center",
                                          fontFamily: "Arial",
                                        }}
                                      >
                                        {note.noteDescription}
                                      </td>
                                      <td>
                                        <Link to={`/list-of-notes/${note._id}`}>
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                          >
                                            Edit
                                          </button>
                                        </Link>
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            openDeleteModel(note._id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </PaginationComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto d-block img-fluid"
              title="Ads"
            />
          </div>
        </div>
      </div>
      {/* End Container fluid  */}
      {/* footer */}
      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <a href="#">Digital Company</a>.
      </footer>
      {/* End footer */}
      <DeleteNote
        show={deleteModal}
        handleClose={closeDeleteModel}
        deleteFunction={deleteNoteHandler}
      />
    </div>
  );
};

export default ShowNotes;
