// import React, { useEffect, useState } from 'react';
// import moment from 'moment';
// import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css'; // Import default styles
// import appConstant from "./../../json/appConstant.json";
// import useApis from "../../apis/useApis";

// const EventCalendar = () => {
//   const localizer = momentLocalizer(moment);
//   const [myEvents, setEvents] = useState([]);

//   const { jsonApi } = useApis();


//   const dateFetch=async()=>{
//     const dateHHh= await jsonApi( appConstant.getAllEvents,
//       null,
//       null)
//       console.log(dateHHh)
//   }

//   useEffect(()=>{
//     dateFetch()
//   },[])

//   return (
//     <div className="page-wrapper">
//       <div className="page-breadcrumb">
//         <div className="row">
//           <div className="db-search d-none d-lg-inline-block"></div>
//           <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
//             Calendar
//           </h3>
//         </div>
//       </div>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-xl-11">
//             <div className="card">
//               <div className="card-body custom-field-button">
//                 <div style={{ height: '80vh' }}>
//                   <Calendar
//                     localizer={localizer}
//                     defaultDate={new Date()}
//                     defaultView={Views.MONTH}
//                     events={myEvents}
//                     components={{
//                       toolbar: CustomToolbar,
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-xl-1 d-none d-xl-block">
//             <img
//               src="assets/images/banner/ads.jpg"
//               className="mx-auto img-fluid"
//               alt="Ads"
//               title="Ads"
//             />
//           </div>
//         </div>
//       </div>
//       <footer className="footer text-center text-muted">
//         All Rights Reserved by Digital Company. Designed and Developed by{" "}
//         <a href="#">Digital Company</a>.
//       </footer>
//     </div>
//   );
// };

// const CustomToolbar = (toolbar) => {
//   return (
//     <div className="rbc-toolbar">
//       <span className="rbc-toolbar-label">{`${toolbar.label}`}</span>
//       <span className="rbc-btn-group">
//         <button onClick={() => toolbar.onNavigate("PREV")}>Back</button>
//         <button onClick={() => toolbar.onNavigate("TODAY")}>Today</button>
//         <button onClick={() => toolbar.onNavigate("NEXT")}>Next</button>
//       </span>
//     </div>
//   );
// };

// export default EventCalendar;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Import default styles
import appConstant from "./../../json/appConstant.json";
import useApis from "../../apis/useApis";

const EventCalendar = () => {
  const localizer = momentLocalizer(moment);
  const [myEvents, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { jsonApi } = useApis();

  // Fetch events from the API
  const dateFetch = async () => {
    try {
  
      // Fetch events from the API
      const { error, data } = await jsonApi(appConstant.getAllEvents, null, null);
      console.log(data.data)
      
      if (!error) {
        // Format the events data
        const events = data.data.map((event) => {
          if (event.date) {
            const startDate = new Date(event.date);
            const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Defaulting to 1-hour duration
  
            return {
              title: event.eventsName,
              start: startDate,
              allDay: true,
              end: endDate
            };
          } else {
            // If date is not present, skip this event
            return null;
          }
        }).filter(event => event !== null); // Remove null entries if any
  
        // Update the state with formatted events
        setEvents(events);
      } else {
        console.error("Error fetching events:", data);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      // Optional: Reset the loading state if used
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    dateFetch();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-breadcrumb">
        <div className="row">
          <div className="db-search d-none d-lg-inline-block"></div>
          <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
            Calendar
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-11">
            <div className="card">
              <div className="card-body custom-field-button">
                <div style={{ height: '80vh' }}>
                  <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView={Views.MONTH}
                    events={myEvents}
                    components={{
                      toolbar: CustomToolbar,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-1 d-none d-xl-block">
            <img
              src="assets/images/banner/ads.jpg"
              className="mx-auto img-fluid"
              alt="Ads"
              title="Ads"
            />
          </div>
        </div>
      </div>
      <footer className="footer text-center text-muted">
        All Rights Reserved by Digital Company. Designed and Developed by{" "}
        <a href="#">Digital Company</a>.
      </footer>
    </div>
  );
};

// Custom toolbar component for the calendar
const CustomToolbar = (toolbar) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{toolbar.label}</span>
      <span className="rbc-btn-group">
        <button onClick={() => toolbar.onNavigate("PREV")}>Back</button>
        <button onClick={() => toolbar.onNavigate("TODAY")}>Today</button>
        <button onClick={() => toolbar.onNavigate("NEXT")}>Next</button>
      </span>
    </div>
  );
};

export default EventCalendar;
