import { createContext, useState } from "react";

export const Context = createContext();
export const AuthContextProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstPassword, setfirstPassword] = useState(false);


  return (
    <Context.Provider
      value={{
        userData,
        setUserData,
        isAuthorized,
        setIsAuthorized,
        isLoading,
        setIsLoading,
        setfirstPassword,
        firstPassword
      }}
    >
      {children}
    </Context.Provider>
  );
};
