import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import SideBar from "../sideBar/SideBar";
import { useState } from "react";


const Aside = () => {

  const [sideBarEnable, setSideBarEnable]=useState(false);

  const toggleSideBar=()=>{
    setSideBarEnable(!sideBarEnable)
  }
  return (
    <>
      <div
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
        className={`${
          sideBarEnable? "show-sidebar" : "hide-sidebar"
        }`} // Apply a class based on sidebar state
      >
        
        <Header toggleSideBar={toggleSideBar} sideBarEnable={sideBarEnable} />

        <SideBar />
        <Outlet />
      </div>
    </>
  );
};

export default Aside;
