import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Mail } from "react-feather";
import {
  MessageCircle,
  Voicemail,
  MessageSquare,
  Airplay,
  ChevronDown,
  Box,
  Settings,
  Calendar,
  Search,
  Phone,
} from "react-feather";
import { Context } from "../../context/Context";
import moment from "moment";
import ImageComponent from "../../media/ImageComponent";

const Header = ({ toggleSideBar, sideBarEnable }) => {
  const { userData } = useContext(Context);
  console.log(userData);

  // const formatTimestamp = (timestamp, formatStr) => {
  //   if (!timestamp) return "N/A";
  //   const date = new Date(timestamp);
  //   // return format(date, "MMMM d, yyyy 'at' h:mm a");
  //   // console.log(date, "M");
  //   // console.log(format(date, "eeee, MMMM d, yyyy 'at' h:mm a"));
  //   // console.log(format(date, "eeee"));
  //   return format(date, formatStr);

  // };

  const formatTimestamp = (timestamp, formatStr) => {
    if (!timestamp) return "N/A";
    const date = moment(timestamp);
    return date.format(formatStr);
  };
  const [selectedStatus, setSelectedStatus] = useState('Away');
  const [dropdown,setDropdown]=useState(false)
  const toggle=()=>{
    setDropdown(!dropdown)
  }

  const handleStatusChange=(status)=>{
    setSelectedStatus(status)
    setDropdown(false)
  }
  


  return (
    <>
      {/* Topbar header*/}
      <header className="topbar" data-navbarbg="skin6">
        <nav className="navbar top-navbar navbar-expand-md">
          <div className="navbar-header" data-logobg="skin6">
            <Link
              className="nav-toggler waves-effect waves-light d-md-none"
              onClick={toggleSideBar}
            >
              <i className={sideBarEnable ? "ti-close" : "ti-menu"}></i>
            </Link>

            <div className="navbar-brand">
              <Link to="#">
                <span className="logo-text">Digital Company</span>
              </Link>
            </div>
            {/* End Logo */}
            {/* Toggle which is visible on mobile only */}
            <Link
              className="topbartoggler d-block d-md-none waves-effect waves-light"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti-more" />
            </Link>
          </div>
          {/* End Logo */}
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            {/* toggle and nav items */}
            <ul className="navbar-nav float-left mr-auto ml-3 pl-1">
              {/* User profile and search */}
              <li className="nav-item mr-3">
                <div className="row">
                  <div className="ml-4 mt-2">
                    
                    <ImageComponent  className="rounded-circle width-40" src={userData?.profileImage} />
                  </div>
                  <div className="ml-2 d-none d-xl-block">
                    <span className="text-dark medium">
                      <b>{userData?.name}</b>
                    </span>
                    <br />
                    <div>
                      <span className="badge badge-warning">Clock Out</span>{" "}
                      {/* <small>Clocked In at 12:33pm</small> */}
                      <small>
                        Clocked In at{" "}
                        {formatTimestamp(userData?.lastSeen, "h:mm a")}
                      </small>
                    </div>
                  </div>
                </div>
              </li>
              {/* User profile and search */}
              <li className="nav-item dropdown ml-3 d-none d-xl-block">
                {/* <span>
                  {formatTimestamp(
                    userData?.lastSeen,
                    "eeee, MMM d, yyyy h:mma"
                  )}
                </span> */}
                <span>
                  {formatTimestamp(
                    userData?.lastSeen,
                    "dddd, MMM D, yyyy h:mma"
                  )}
                </span>

                <br />
                {/* <div className="input-group user-status">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Status</span>
                  </div>
                  <select className="vodiapicker border-0 input-group-text" onClick={toggle}>
                    <option value="au" data-thumbnail="/assets/images/away.png">
                      Away
                    </option>
                    <option
                      value="en"
                      className="test"
                      data-thumbnail="/assets/images/busy.png"
                    >
                      Busy
                    </option>
                    <option
                      value="uk"
                      data-thumbnail="/assets/images/do-not-disturb.png"
                    >
                      Do Not Disturb
                    </option>
                  </select>
                  <div className="lang-select border-0">
                    <button className="btn-select" value="au">
                      <li>
                        <img src="/assets/images/away.png" alt="" />
                        <span>Away</span>
                      </li>
                    </button>
                    <div className="b" style={{ display: "none" }}>
                      <ul id="a">
                        <li>
                          <img
                            src="/assets/images/busy.png"
                            alt=""
                            value="busy"
                          />
                          <span>Busy</span>
                        </li>
                        <li>
                          <img
                            src="/assets/images/away.png"
                            alt=""
                            value="away"
                          />
                          <span>Away</span>
                        </li>
                        <li>
                          <img
                            src="/assets/images/do-not-disturb.png"
                            alt=""
                            value="do-not-disturb"
                          />
                          <span>Do Not Disturb</span>
                        </li>
                      </ul>
                    </div>
                    <ChevronDown className="form-control-icon" onClick={toggle}/>
                  </div>
                </div> */}

                  <div className="input-group user-status">
      <div className="input-group-prepend">
        <span className="input-group-text">Status</span>
      </div>
      <select 
        className="vodiapicker border-0 input-group-text" 
        onChange={(e) => handleStatusChange(e.target.value)}
        value={selectedStatus}
      >
         <option value="Busy" data-thumbnail="/assets/images/busy.png">Busy</option>
        <option value="Away" data-thumbnail="/assets/images/away.png">Away</option>
        <option value="Do Not Disturb" data-thumbnail="/assets/images/do-not-disturb.png">Do Not Disturb</option>
      </select>
      <div className="lang-select border-0">
        <button className="btn-select" onClick={toggle}>
          <li>
            <img 
              src={`/assets/images/${selectedStatus.toLowerCase().replace(/ /g, '-')}.png`} 
              alt={selectedStatus} 
            />
            <span>{selectedStatus}</span>
          </li>
        </button>
        <div className="b" style={{ display: dropdown ? 'block' : 'none' }}>
          <ul id="a">
          <li onClick={() => handleStatusChange('Busy')}>
              <img src="/assets/images/busy.png" alt="Busy" />
              <span>Busy</span>
            </li>
            <li onClick={() => handleStatusChange('Away')}>
              <img src="/assets/images/away.png" alt="Away" />
              <span>Away</span>
            </li>
           
            <li onClick={() => handleStatusChange('Do Not Disturb')}>
              <img src="/assets/images/do-not-disturb.png" alt="Do Not Disturb" />
              <span>Do Not Disturb</span>
            </li>
          </ul>
        </div>
        <ChevronDown className="form-control-icon" onClick={toggle} />
      </div>
    </div>

              </li>
            </ul>
            {/* Right side toggle and nav items */}
            <ul className="navbar-nav float-right border-topnotify">
              {/* Email */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <Mail className="svg-icon" />
                    </span>
                    <span className="badge badge-primary notify-no rounded-circle">
                      2
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Email
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Email */}
              {/* Chat */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <MessageCircle className="svg-icon" />
                    </span>
                    <span className="badge badge-success notify-no rounded-circle">
                      7
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Chats
                    </span>
                  </div>
                </Link>

                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Chat */}
              {/* Voicemail */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  to="#"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <Voicemail className="svg-icon" />
                    </span>
                    <span className="badge badge-danger notify-no rounded-circle">
                      5
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Voicemails
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Voicemail */}
              {/* Text */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin navHoverPointer"
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <MessageSquare className="svg-icon" />
                    </span>
                    <span className="badge badge-warning notify-no rounded-circle">
                      7
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Unanswered
                      <br />
                      Text
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all mails</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Text */}
              {/* Phone */}
              <li className="nav-item dropdown navHoverPointer">
                <Link
                  className="nav-link dropdown-toggle pl-md-3 position-relative extra-margin "
                  id="bell"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="notify-lebel">
                    <span>
                      <Phone class="svg-icon" />
                    </span>
                    <span className="badge badge-secondary rounded-circle">
                      7
                    </span>
                  </div>
                  <div className="notify-lebelbtm">
                    <span className="small">
                      Missed
                      <br />
                      Calls
                    </span>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                  <ul className="list-style-none">
                    <li>
                      <div className="message-center notifications position-relative">
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <div className="btn btn-danger rounded-circle btn-circle">
                            <Airplay className="text-white" />
                          </div>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Luanch Admin
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my new admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:30 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-success text-white rounded-circle btn-circle">
                            <Calendar className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Event today
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              Just a reminder that you have event
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:10 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-info rounded-circle btn-circle">
                            <i data-feather="settings" />
                            <Settings className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Settings
                            </h6>
                            <span className="font-12 text-nowrap d-block text-muted text-truncate">
                              You can customize this template as you want
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:08 AM
                            </span>
                          </div>
                        </Link>
                        {/* Message */}
                        <Link
                          to="!#"
                          className="message-item d-flex align-items-center border-bottom px-3 py-2"
                        >
                          <span className="btn btn-primary rounded-circle btn-circle">
                            <Box className="text-white" />
                          </span>
                          <div className="w-75 d-inline-block v-middle pl-2">
                            <h6 className="message-title mb-0 mt-1">
                              Pavan kumar
                            </h6>{" "}
                            <span className="font-12 text-nowrap d-block text-muted">
                              Just see the my admin!
                            </span>
                            <span className="font-12 text-nowrap d-block text-muted">
                              9:02 AM
                            </span>
                          </div>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="nav-link pt-3 text-center text-dark"
                        to="#javascript:void(0);"
                      >
                        <strong>Check all calls</strong>
                        <i className="fa fa-angle-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* End Phone */}
              {/* Search */}
              <li className="nav-item d-none d-xl-block">
                <div className="nav-link">
                  <form>
                    <div className="customize-input">
                      <input
                        className="form-control custom-shadow custom-radius border-0 bg-white"
                        type="search"
                        maxLength={256}
                        placeholder="Google Search"
                        aria-label="Search"
                      />
                      <Search className="form-control-icon" />
                    </div>
                  </form>
                </div>
              </li>
              {/* User profile and search */}
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
