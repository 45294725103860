import React, { useContext, useEffect, useState } from "react";
import DialPad from "./DialPad";
import CallHistory from "./CallHistory";
import Contact from "./Contact";
import { Plus } from "react-feather";
import useApis from "../../apis/useApis";
import appConstant from "./../../json/appConstant.json";
import { Device } from "@twilio/voice-sdk";
import { Context } from "../../context/Context";
import { Controller, useForm } from "react-hook-form";
import ModelUi from "../model/ModelUi";
import {
  validateName,
  validatePhoneNumberWithCountryCode,
} from "../validation/Validation";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import DragsAndDrop from "../../image/DragsAndDrop";

const CallCentre = () => {
  const { jsonApi, mediaApi } = useApis();
  const { userData } = useContext(Context);
  const [to, setTo] = useState("");
  const [picked, setPicked] = useState([]);
  const [contact, setContact] = useState([]);
  const [contactTotal, setContactTotal] = useState(0);
  const [contactPage, setContactPage] = useState(1);
  const [contactLimit, setContactLimit] = useState(10);
  const [history, setHistory] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [historyPage, setHistoryPage] = useState(1);
  const [historyLimit, setHistoryLimit] = useState(10);
  const [deviceInputAvailable, setDeviceInputAvailable] = useState([]);
  const [defaultDeviceInput, setDefaultDeviceInput] = useState("");
  const [deviceOutputAvailable, setDeviceOutputAvailable] = useState([]);
  const [defaultDeviceOutput, setDefaultDeviceOutput] = useState("");
  const [from, setFrom] = useState("+12024172529");
  const [device, setDevice] = useState(null);
  const [call, setCall] = useState(null);
  const [callSessionId, setCallSessionId] = useState("fgdfgfg");
  const [searchHistory, setSearchHistory] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [error, setError] = useState("");
  const [logoPreview, setLogoPreview] = useState(null);
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  const [inCall, setInCall] = useState(false); // Flag for active call
  const [callDuration, setCallDuration] = useState(0); // in seconds
  const [durationInterval, setDurationInterval] = useState(null); // store interval ID
  const [currentCallData, setCurrentCallData] = useState(null); // store interval ID

  const init = async () => {
    const res = await jsonApi(
      appConstant.createCallToken,
      null,
      { identity: userData._id },
      null
    );

    const token = res.data.token;
    const newDevice = new Device(token, {
      logLevel: 1,
      codecPreferences: ["opus", "pcmu"],
    });
    setDevice(newDevice);
  };

  const fetchContact = async () => {
    try {
      const contactRes = await jsonApi(
        appConstant.getContact,
        {
          page: contactPage,
          limit: contactLimit,
          search: searchContact,
          type: "Call",
        },
        null,
        true
      );
      if (!contactRes.error) {
        setContact(contactRes.data.data);
        setContactTotal(contactRes.data.total);
      } else {
        console.error(contactRes.message);
      }
    } catch (err) {
      console.error("Error fetching contacts:", err);
    }
  };

  const fetchHistory = async () => {
    const historyRes = await jsonApi(
      appConstant.getCallHistory,
      { page: historyPage, limit: historyLimit, search: searchHistory },
      null,
      true
    );
    if (!historyRes.error) {
      // console.log(historyRes)
      setHistory(historyRes.data.data);
      setHistoryTotal(historyRes.data.total);
      // setHistoryPage(historyRes.data.page)
      // console.log(historyRes.data.page);
    } else {
      // setInitError(historyRes.message ? historyRes.message : "Something went wrong");
      console.error(historyRes.message);
    }
  };

  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  useEffect(() => {
    fetchHistory();
  }, [searchHistory, historyPage, historyLimit]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    fetchContact();
  }, [searchContact, contactPage, contactLimit]);

  const resetCallSessionId = () => {
    const newSessionId = `${Math.trunc(
      Math.random() * 9999999
    )}${new Date().getTime()}`;
    setCallSessionId(newSessionId);
    return newSessionId;
  };
  useEffect(() => {
    if (device) {
      try {
        addDeviceListeners(callSessionId);
        device.register();
      } catch (err) {
        console.error("Error in device setup:", err);
      }

      return () => {
        addDeviceListeners(callSessionId);
      };
    }
  }, [device]);

  const {
    control,
    setValue,
    clearErrors,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const updateDevices = (selectEl, selectedDevices) => {
    device.audio.availableOutputDevices.forEach(function (device, id) {
      let isActive = selectedDevices.size === 0 && id === "default";
      selectedDevices.forEach(function (device) {
        if (device.deviceId === id) {
          isActive = true;
        }
      });
    });
  };
  const updateAllAudioDevices = () => {
    if (device) {
      updateDevices("speakerDevices", device.audio.speakerDevices.get());
      updateDevices("ringtoneDevices", device.audio.ringtoneDevices.get());
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("type", "Call");
    if (data.logo) {
      formData.append("logo", data.logo); // data.logo is a FileList, get the first file
    }
    const response = await mediaApi(
      appConstant.createContact,
      null,
      formData,
      null
    ); // Pass token to API call
    if (!response.error) {
      reset();
      createContactModelHandleClose();
      await fetchContact();
    } else {
    }
  };

  // const handleEndCall = async () => {
  //   try {
  //     const seId = resetCallSessionId();
  //     addDeviceListeners(seId);
  //     call.disconnect();
  //     await init();
  //   } catch (e) {
  //     console.error("Error ending call:", e);
  //   }
  // };

  const handleEndCall = async () => {
    if (call) {
      call.disconnect(); // End the call
      setInCall(false);
      await fetchHistory();
      if (durationInterval) {
        clearInterval(durationInterval);
      }
      setCallDuration(0);
    }
  };

  const makeCall = async (phoneNumber) => {
    if (device) {
      const callId = resetCallSessionId();
      const number = phoneNumber ? phoneNumber : history[0]?.phone;

      const params = { To: number, session_id: callId };
      setCallSessionId(callId);
      const newCall = await device.connect({ params });
      // Start tracking the duration
      if (durationInterval) {
        clearInterval(durationInterval);
      }

      setCallDuration(0);
      const interval = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);
      setDurationInterval(interval); // Save interval ID for later clearing

      newCall.on("accept", async () => {
        console.log("Call accepted");
        await fetchHistory();
      });

      newCall.on("disconnect", async () => {
        try {
          console.log("Call disconnected");
          setInCall(false);
          setPicked("");
          // Stop tracking the duration
          await fetchHistory();
          if (durationInterval) {
            clearInterval(durationInterval);
          }

          setCallDuration(0);
        } catch (error) {
          console.log("error", error);
        }
        // Reset call duration
      });

      newCall.on("reject", async () => {
        try {
          setPicked("");
          setInCall(false);
          console.log("Call rejected");
          await fetchHistory();
          if (durationInterval) {
            clearInterval(durationInterval);
          }

          setCallDuration(0); // Reset call duration
        } catch (error) {
          console.log("eeror", error);
        }
      });
      setCall(newCall);
      setPicked(`Calling ${number}`);
      addDeviceListeners(callId);
      setInCall(true);
      await jsonApi(
        appConstant.createCallHistory,
        null,
        { phone: number, userNumber: from, type: "Test" },
        true
      );
    }
  };
  console.log(currentCallData);

  const addDeviceListeners = (seId) => {
    device.on("registered", function () {
      console.log("Twilio.Device Ready to make and receive calls!");
    });
    device.on("tokenWillExpire", async () => {
      const response = await init();
      device.updateToken(response.token);
    });
    device.on("error", function (error) {
      console.error("Twilio.Device Error: " + error.message);
    });
    device.on("incoming", (callData) => handleIncomingCall(callData, seId));
    device.audio.on("deviceChange", () => {
      updateAllAudioDevices.bind(device);
      const deviceOutputAvailableArray = [];
      device.audio.availableOutputDevices.forEach((device, id) => {
        var deviceOption = document.createElement("option");
        deviceOption.label = device.label;
        deviceOption.setAttribute("data-id", id);
        deviceOutputAvailableArray.push({ label: device.label, id: id });
      });
      if (deviceOutputAvailableArray.length !== 0) {
        setDefaultDeviceOutput(deviceOutputAvailableArray[0].id);
      }
      setDeviceOutputAvailable(deviceOutputAvailableArray);
      const deviceInputAvailableArray = [];
      device.audio.availableInputDevices.forEach((device, id) => {
        var deviceOption = document.createElement("option");
        deviceOption.label = device.label;
        deviceOption.setAttribute("data-id", id);
        deviceInputAvailableArray.push({ label: device.label, id: id });
      });
      setDeviceInputAvailable(deviceInputAvailableArray);
      if (deviceInputAvailableArray.length !== 0) {
        setDefaultDeviceInput(deviceInputAvailableArray[0].id);
      }
    });

    device.on("connect", async () => {
      console.log("Call connected");
      setOpenIncomingModal(false);
      setInCall(true);
      setPicked("");
      await fetchHistory();
    });

    device.on("disconnect", async () => {
      try {
        console.log("Call disconnected");
        setOpenIncomingModal(false);
        setInCall(false);
        setCall(null);
        setPicked("");
        // Stop tracking the duration
        await fetchHistory();
        if (durationInterval) {
          clearInterval(durationInterval);
        }
        setCallDuration(0); // Reset call duration
      } catch (error) {
        console.log("eeror", error);
      }
    });

    const handleIncomingCall = async (callData, sessionIdData) => {
      console.log("callSessionId", sessionIdData);
      delay(3000);
      setCall(callData);
      setInCall(true);
      setOpenIncomingModal(true);
      setPicked(`Received Call ${callData.parameters.From}`);

      console.log(callData?.parameters?.From);
      await fetchHistory();
      const resData = await jsonApi(
        appConstant.getCurrentLeadCall,
        null,
        { phone: callData?.parameters?.From },
        true
      );
      if (!resData.error) {
        setCurrentCallData(resData.data.data);
      } else {
        console.error(resData.message);
        setCurrentCallData(null);
      }
    };
  };

  const handleAcceptCall = () => {
    if (call) {
      call.accept(); // Accept the incoming call
      setOpenIncomingModal(false);
      setInCall(true);
      if (durationInterval) {
        clearInterval(durationInterval);
      }
      setCallDuration(0);

      const interval = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      setDurationInterval(interval); // Save interval ID for later clearing
    }
  };

  const handleRejectCall = () => {
    try {
      if (call) {
        call.reject(); // Reject the incoming call
        setInCall(false);
        setOpenIncomingModal(false);
        if (durationInterval) {
          clearInterval(durationInterval);
        }

        setCallDuration(0); // Reset call duration
      }
    } catch (error) {
      console.log("eeror", error);
    }
  };

  const [createContactModel, setCreateContactModel] = useState(false);

  const createContactModelHandleClose = async () => {
    reset();
    setCreateContactModel(false);
  };

  const createContactModelHandleOpen = async () => {
    setCreateContactModel(true);
  };

  function changeInputDevice(deviceId) {
    const constraints = { audio: { deviceId: { exact: deviceId } } };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {})
      .catch((error) => {
        console.error("Error accessing new microphone:", error);
      });
  }

  const getMoreHistory = () => {
    setHistoryLimit((pre) => pre + 5);
  };

  const getMoreContact = () => {
    setContactLimit((pre) => pre + 5);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  // Watch the phone input field
  const phoneValue = watch("phone", ""); // Provide default empty string to avoid undefined issues

  useEffect(() => {
    // Disable the input when the phone number is exactly 13 digits long and starts with +
    if (phoneValue && /^\+\d{13}$/.test(phoneValue)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [phoneValue]);
  console.log(errors);
  return (
    <>
      <div className="page-wrapper">
        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-12 align-self-center">
              <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                Call Centre
              </h3>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-11">
              <div className="card mb-3"></div>
              <div className="row">
                <DialPad
                  makeCall={makeCall}
                  picked={picked}
                  setPicked={setPicked}
                  handleEndCall={handleEndCall}
                  inCall={inCall}
                  callDuration={callDuration}
                />
                <CallHistory
                  makeCall={makeCall}
                  picked={picked}
                  setPicked={setPicked}
                  history={history}
                  setSearchHistory={setSearchHistory}
                  total={historyTotal}
                  getMoreHistory={getMoreHistory}
                  fetchHistory={fetchHistory}
                  limit={historyLimit}
                />
                <Contact
                  contact={contact}
                  makeCall={makeCall}
                  picked={picked}
                  setPicked={setPicked}
                  setSearchContact={setSearchContact}
                  total={contactTotal}
                  getMoreContact={getMoreContact}
                  fetchContact={fetchContact}
                  limit={contactLimit}
                />
              </div>
            </div>
            <div className="col-xl-1 d-none d-xl-block">
              <img
                src="/assets/images/banner/ads.jpg"
                className="mx-auto d-block img-fluid"
                title="Ads"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <a
        onClick={createContactModelHandleOpen}
        className="add_floating_btn badge-primary"
      >
        <Plus className="feather-icon" />
      </a>
      <ModelUi
        show={createContactModel}
        handleClose={createContactModelHandleClose}
        title="Create Contact"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="row">
            <div className="col-12 mb-3">
              <label>
                Name <span className="text-danger">*</span>
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <input
                    type="text"
                    placeholder="Name"
                    maxLength={256}
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    {...register("name", {
                      required: "Name is required",
                      validate: (value) => validateName(value, "name"),
                    })}
                  />
                )}
              />
              {errors.name && (
                <p className="text-danger">{errors.name.message}</p>
              )}
            </div>

            <div className="col-12 mb-3">
              <label>
                Phone <span className="text-danger">*</span>
              </label>
             
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Corporate phone is required",
                  validate: validatePhoneNumberWithCountryCode,
                }}
                render={({ field }) => (
                  <>
                    <PhoneInput
                      country={"eg"}
                      enableSearch={true}
                      className={`w-100 ${errors.name ? "is-invalid" : ""}`}
                      value={field.value}
                      onChange={(phone) => field.onChange(phone)}
                    />
                  </>
                )}
              />
              {errors.phone && (
                <p className="text-danger">{errors.phone.message}</p>
              )}
            </div>

            <div className="col-12 mb-3">
              <label>
                Logo <span className="text-danger"></span>
              </label>
              <div className="d-flex justify-content-center">
                <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  aspect={1 / 1}
                  uploadFile={(x) => {
                    setValue("logo", x);
                    clearErrors("logo");
                  }}
                />
              </div>
           
              {errors.logo && (
                <p className="text-danger">{errors.logo.message}</p>
              )}
              {logoPreview && (
                <div className="mt-2">
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Create
            </button>
          </div>
        </form>
      </ModelUi>

      <ModelUi
  show={openIncomingModal}
  handleClose={() => setOpenIncomingModal(false)}
  title={currentCallData ? "Incoming Lead" : "Incoming Call"}
>
  {currentCallData ? (
    <>
      <h3>You have an incoming lead...</h3>
      <h4>
        Lead Information
      </h4>
      <p><strong>Name:</strong> {currentCallData.primaryName}</p>
      <p><strong>Contact Number:</strong> {currentCallData.primaryContactNumber}</p>
      <p><strong>Email:</strong> {currentCallData.email}</p>
      <p><strong>Street Address:</strong> {currentCallData.streetAddress}</p>
      <p><strong>City:</strong> {currentCallData.city}</p>
      <p><strong>State:</strong> {currentCallData.state}</p>
      <p><strong>Zip Code:</strong> {currentCallData.zipCode}</p>
      <p><strong>Lead Type:</strong> {currentCallData.leadType}</p>
      <p><strong>Lead Source:</strong> {currentCallData.leadSource}</p>
    </>
  ) : (
    <h3>You have an incoming call...</h3>
  )}
  
  {!currentCallData && (
    <h4>
      From: {call?.parameters?.From}
    </h4>
  )}

  <div className="text-center">
    <button
      type="button"
      className="btn btn-primary m-2"
      onClick={handleAcceptCall}
    >
      Accept Call
    </button>
    <button
      type="button"
      className="btn btn-danger m-2"
      onClick={handleRejectCall}
    >
      Reject Call
    </button>
  </div>
</ModelUi>
    </>
  );
};

export default CallCentre;
