import React, { useEffect, useState } from "react";
import businessData from "../../../json/businessData.json";
import { Controller, useForm } from "react-hook-form";
import appConstant from "../../../json/appConstant.json";
import useApis from "../../../apis/useApis";
import {
  validateName,
  validatePhoneNumberWithCountryCode,
  validateEmail,
  validateZipCode,
  validateSuiteNumber,
  validateSicCode,
  validateMaicsCode,
  validateGlCode,
  validateURL,
  validateNumber,
  validatePercentOwn,
} from "../../validation/Validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PaginationComponent from "../../pagination/PaginationComponent";
import FavButton from "../business/humanResources/FavButton";
import useContactHuman from "../../../hooks/useContactHuman";
import { PhoneInput } from "react-international-phone";
import "./asa.css";

const BusinessInformation = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const { jsonApi } = useApis();
  const [dateError, setDateError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const type = ["Business Info"];

  const [search, setSearch] = useState("");

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      type: "Business Info",
    };
    const response = await jsonApi(
      appConstant.businessDetail,
      null,
      formData,
      null
    );
    reset();
    console.log(response);
  };

  const hasError = Object.keys(errors).length > 0;
  const getGeneralErrorMessage = () => {
    if (hasError) {
      return "Enter all the details!";
    }
    return "";
  };

  const today = new Date();
  const maxDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      setSelectedDate(date);
      setValue("dateBusinessStarted", date, { shouldValidate: true });
      setDateError("");
    } else {
      setDateError("Invalid date format. Please select a valid date.");
    }
  };
  
  const {
    error,
    contactDetails: allContactDetails,
    totalData,
    fetchContactHuman,
  } = useContactHuman(type, page, limit, search);

  const splitAddressIntoLines = (address, maxLength = 30) => {
    if (!address) return null;

    const lines = [];
    for (let i = 0; i < address.length; i += maxLength) {
      lines.push(address.slice(i, i + maxLength));
    }
    // console.log(lines)
    return lines;
  };

  // useEffect(()=>{
  //   if(Object.keys(errors).length>0){
  //     setError(getGeneralErrorMessage());

  //   const setTimeoutId= setTimeout(()=>{
  //     setError('')
  //     },3000)
  //     return ()=> clearTimeout(setTimeoutId)
  //   }
  // },[errors])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-11">
          <div className="card">
            <div className="card-body custom-field-button">
              <div className="row mb-5">
                <div className="col-lg-4">
                  {businessData.fields.map((item, index) => (
                    <div
                      className="row align-items-center mb-3 mb-sm-0"
                      key={index}
                    >
                      <label className="col-md-5 col-sm-4 mb-0 font-weight-bold">
                        {item.label}
                      </label>
                      <div className="col-md-7 col-sm-8">
                        <input
                          maxLength={256}
                          type={item.type}
                          className={item.class}
                          placeholder={item.value}
                          readOnly={item.readOnly}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-lg-8">
                  <textarea
                    className="form-control"
                    id=""
                    rows={8}
                    value=""
                    placeholder={"Notes: 01/01/22, 6:00 AM John Doe"}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-5">
                  <div className="table-responsive">
                    <div
                      id="default_order_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <PaginationComponent
                        setPage={setPage}
                        totalData={totalData}
                        limit={limit}
                        setLimit={setLimit}
                        page={page}
                        setSearch={setSearch}
                      >
                        <div className="row">
                          <div className="col-12 mb-2">
                            <table
                              id="default_order"
                              className="table table-hover table-striped display no-wrap w-100"
                            >
                              <thead>
                                <tr>
                                  {businessData.headers3.map(
                                    (header, index) => (
                                      <th scope="col" key={index}>
                                        {header}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {allContactDetails &&
                                allContactDetails.length > 0 ? (
                                  allContactDetails.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {(page - 1) * limit + index + 1}
                                          <FavButton
                                            item={item}
                                            init={fetchContactHuman}
                                          />
                                        </td>

                                        <td>{item.primaryName || "No Data"}</td>
                                        <td>
                                          {item.businessName || "No Data"}
                                        </td>
                                        <td>
                                          {item.mainCompanyNumber || "No Data"}
                                        </td>
                                        <td>{item.email || "No Data"}</td>
                                        <td>
                                          {item.employmentLevel || "No Data"}
                                        </td>
                                        <td>
                                          {splitAddressIntoLines(
                                            item.streetAddress || "No Data"
                                          ).map((line, index) => (
                                            <div key={index}>{line}</div>
                                          ))}
                                        </td>
                                        {/* <td>
                                            {" "}
                                            <Edit2
                                              style={{ cursor: "pointer" }}
                                              className="ml-1"
                                              onClick={() =>
                                                handleEditClick(item)
                                              }
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <Trash
                                              style={{ cursor: "pointer" }}
                                              className="ml-1"
                                              onClick={() =>
                                                handleDeleteClick(item._id)
                                              }
                                            />
                                          </td> */}
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="5">No data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </PaginationComponent>
                    </div>
                  </div>
                </div>
                {/*/Col*/}
              </div>
              <h4 className="page-title text-truncate text-dark font-weight-medium mb-2">
                Business Information
              </h4>
              <h6 className="page-title text-truncate text-dark font-weight-medium mb-4">
                Primary Contact 1
              </h6>
              <form onSubmit={handleSubmit(onSubmit)}>
                {hasError && (
                  <div className="alert alert-danger text-center">
                    {/* {getGeneralErrorMessage()} */}
                    {getGeneralErrorMessage()}
                  </div>
                )}
                <div className="row mb-5">
                <div className="row mb-5">
  <div className="container m-4">
    {/* First Row */}
    <div className="row">
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="companyName">Company Name</label>
          <input
            type="text"
            maxLength={256}
            className="form-control"
            id="companyName"
            placeholder="ABC Company"
            {...register("companyName", {
              required: true,
              validate: (value) => validateName(value, "name"),
            })}
          />
          {errors.companyName && (
            <p className="text-danger">{errors.companyName.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="primaryName">Primary Name</label>
          <input
            type="text"
            maxLength={256}
            placeholder="John"
            className="form-control"
            id="primaryName"
            {...register("primaryName", {
              required: true,
              validate: (value) => validateName(value, "name"),
            })}
          />
          {errors.primaryName && (
            <p className="text-danger">{errors.primaryName.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="mainCompanyNumber">Main Company #</label>
          <Controller
            name="mainCompanyNumber"
            control={control}
            rules={{
              required: "Company number is required",
              validate: validatePhoneNumberWithCountryCode,
            }}
            render={({ field }) => (
              <div className="form-control-container">
                <PhoneInput
                  country="eg"
                  enableSearch
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)}
                  inputClass="form-control"
                  containerClass="phone-input-container"
                />
                {errors.mainCompanyNumber && (
                  <p className="text-danger">{errors.mainCompanyNumber.message}</p>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>

    {/* Second Row */}
    <div className="row">
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="title">Title/Surname</label>
          <input
            type="text"
            maxLength={256}
            className="form-control"
            id="title"
            placeholder="Smith"
            {...register("title", {
              required: true,
              validate: (value) => validateName(value, "Title"),
            })}
          />
          {errors.title && (
            <p className="text-danger">{errors.title.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="employmentLevel">Employee Level</label>
          <input
            type="text"
            className="form-control"
            id="employmentLevel"
            placeholder="Employee Level"
            maxLength={256}
            {...register("employmentLevel", {
              required: true,
              validate: (value) => validateName(value, "employee"),
            })}
          />
          {errors.employmentLevel && (
            <p className="text-danger">{errors.employmentLevel.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="primaryContactNumber">Primary Contact #</label>
          <Controller
            name="primaryContactNumber"
            control={control}
            rules={{
              required: "Primary number is required",
              validate: validatePhoneNumberWithCountryCode,
            }}
            render={({ field }) => (
              <>
                <PhoneInput
                  country="eg"
                  enableSearch
                  value={field.value}
                  onChange={(phone) => field.onChange(phone)}
                />
                {errors.primaryContactNumber && (
                  <p className="text-danger">{errors.primaryContactNumber.message}</p>
                )}
              </>
            )}
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            maxLength={256}
            placeholder="abc@gmail.com"
            {...register("email", {
              required: true,
              validate: validateEmail,
            })}
          />
          {errors.email && (
            <p className="text-danger">{errors.email.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="isOwner">Owner</label>
          <div className="d-flex mt-2">

          <div className="form-check ">
            <input
              className="form-check-input"
              type="radio"
              name="isOwner"
              value="Yes"
              {...register("isOwner", {
                required: true,
              })}
            />
            <label className="form-check-label">Yes</label>
          </div>
          <div className="form-check mx-3">
            <input
              className="form-check-input"
              type="radio"
              name="isOwner"
              value="No"
              {...register("isOwner", {
                required: true,
              })}
            />
            <label className="form-check-label">No</label>
          </div>
          </div>
          {errors.isOwner && (
            <p className="text-danger">{errors.isOwner.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="percentOwn">Percent Own</label>
          <div className="row align-items-center">
            <div className="col-9 input-group">
              <input
                type="number"
                className="form-control"
                id="percentOwn"
                placeholder="75"
                {...register("percentOwn", {
                  required: true,
                  validate: validatePercentOwn,
                  valueAsNumber: true,
                })}
                max="100"
                min="1"
                step="1"
                onInput={(e) => {
                  let value = e.target.value.replace(/[^0-9]/g, "");
                  if (value.length > 3) {
                    value = value.slice(0, 3);
                  }
                  if (Number(value) > 100) {
                    value = "100";
                  }
                  e.target.value = value;
                }}
              />
              <div className="input-group-prepend">
                <div className="input-group-text">%</div>
              </div>
            </div>
            {errors.percentOwn && (
              <p className="text-danger">{errors.percentOwn.message}</p>
            )}
          </div>
        </div>
      </div>
    </div>

    {/* Third Row */}
    <div className="row">
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="streetAddress">Street Address</label>
          <input
            type="text"
            className="form-control"
            id="streetAddress"
            maxLength={256}
            placeholder="1435 Carewood Drive"
            {...register("streetAddress", {
              required: true,
            })}
          />
          {errors.streetAddress && (
            <p className="text-danger">{errors.streetAddress.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="suiteNumber">Suite #</label>
          <input
            type="text"
            className="form-control"
            id="suiteNumber"
            maxLength={256}
            placeholder="9859"
            {...register("suiteNumber", {
              required: true,
              validate: validateSuiteNumber,
            })}
          />
          {errors.suiteNumber && (
            <p className="text-danger">{errors.suiteNumber.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            type="text"
            className="form-control"
            placeholder="Amsterdam"
            maxLength={128}
            id="city"
            {...register("city", {
              required: true,
              validate: (value) => validateName(value, "city"),
            })}
          />
          {errors.city && (
            <p className="text-danger">{errors.city.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="state">State</label>
          <input
            type="text"
            className="form-control"
            placeholder="CA"
            id="state"
            maxLength={128}
            {...register("state", {
              required: true,
              validate: (value) => validateName(value, "state"),
            })}
          />
          {errors.state && (
            <p className="text-danger">{errors.state.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group">
          <label htmlFor="zip">Zip Code</label>
          <input
            type="text"
            className="form-control"
            id="zip"
            maxLength={5}
            placeholder="89521"
            {...register("zip", {
              required: true,
              validate: validateZipCode,
            })}
          />
          {errors.zip && (
            <p className="text-danger">{errors.zip.message}</p>
          )}
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group ">
          <label htmlFor="url" >URL</label>
          
            <input
              type="text"
              className="form-control"
              id="url"
              maxLength={256}
              placeholder="https://www.abccompany.com"
              {...register("url", {
                required: true,
                validate: validateURL,
              })}
            />
            {errors.url && (
              <p className="text-danger">{errors.url.message}</p>
            )}
          </div>
        
      </div>
    </div>

    {/* Fourth Row */}
    <div className="row">
     
      <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="sicCode" >
              SIC Code
            </label>
           
              <input
                type="text"
                className="form-control"
                id="sicCode"
                maxLength={128}
                placeholder="8457"
                {...register("sicCode", {
                  required: true,
                  validate: validateSicCode,
                })}
              />
              {errors.sicCode && (
                <p className="text-danger">{errors.sicCode.message}</p>
              )}
            </div>
          
        </div>
        {/* MAICS Code */}
        <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="maicsCode" >
              MAICS Code
            </label>
              <input
                type="text"
                className="form-control"
                id="maicsCode"
                maxLength={256}
                placeholder="584875"
                {...register("maicsCode", {
                  required: true,
                  validate: validateMaicsCode,
                })}
              />
              {errors.maicsCode && (
                <p className="text-danger">{errors.maicsCode.message}</p>
              )}
            </div>
       
        </div>
        {/* GL Code */}
        <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="glCode" >
              GL Code
            </label>
           
              <input
                type="text"
                className="form-control"
                id="glCode"
                maxLength={256}
                placeholder="A100"
                {...register("glCode", {
                  required: true,
                  validate: validateGlCode,
                })}
              />
              {errors.glCode && (
                <p className="text-danger">{errors.glCode.message}</p>
              )}
            </div>
          
        </div>
        {/* Legal Entity Type */}
        <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="legalEntityType" >
              Legal Entity Type
            </label>
          
              <input
                type="text"
                className="form-control"
                id="legalEntityType"
                maxLength={256}
                placeholder=""
                {...register("legalEntityType", {
                  required: true,
                  validate: (value) =>
                    validateName(value, "Legal Entity Type"),
                })}
              />
              {errors.legalEntityType && (
                <p className="text-danger">{errors.legalEntityType.message}</p>
              )}
            </div>
         
        </div>
        {/* Date Business Started */}
        <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="dateBusinessStarted" >
              Date Business Started
            </label>
          
              <DatePicker
                selected={selectedDate}
                onChange={(date) => handleDateChange(date)}
                dateFormat="dd/MM/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={maxDate}
                customInput={
                  <input type="text" className="form-control" />
                }
              />
              {errors.dateBusinessStarted && (
                <p className="text-danger">{errors.dateBusinessStarted.message}</p>
              )}
            </div>
          </div>
       
        {/* Tax ID Number */}
        <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="taxIdNumber" >
              Tax ID Number
            </label>
          
              <input
                type="text"
                className="form-control"
                id="taxIdNumber"
                maxLength={256}
                placeholder="123-45-6789"
                {...register("taxIdNumber", {
                  required: true,
                  validate: validateNumber,
                })}
              />
              {errors.taxIdNumber && (
                <p className="text-danger">{errors.taxIdNumber.message}</p>
              )}
            </div>
          </div>
      
        {/* Industry */}
        <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="industry" >
              Industry
            </label>
            
              <input
                type="text"
                className="form-control"
                id="industry"
                maxLength={256}
                placeholder="Construction"
                {...register("industry", {
                  required: true,
                  validate: (value) =>
                    validateName(value, "industry"),
                })}
              />
              {errors.industry && (
                <p className="text-danger">{errors.industry.message}</p>
              )}
            </div>
          </div>
      
        {/* Name Of Business */}
        <div className="col-md-4">
          <div className="form-group ">
            <label htmlFor="businessName" >
              Name Of Business
            </label>
           
              <input
                type="text"
                className="form-control"
                id="businessName"
                maxLength={256}
                placeholder="ABC Construction"
                {...register("businessName", {
                  required: true,
                  validate: (value) =>
                    validateName(value, "business name"),
                })}
              />
              {errors.businessName && (
                <p className="text-danger">{errors.businessName.message}</p>
              )}
            </div>
          </div>
       
    </div>
  </div>
</div>


                  <div className="col-sm-10 offset-sm-2">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-1 d-none d-xl-block">
          <img
            src="/assets/images/banner/ads.jpg"
            className="mx-auto img-fluid"
            title="Ads"
            alt="Ads"
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;
